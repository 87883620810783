import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    forwardRef,
    Host,
    Input,
    OnInit,
    Optional,
    SkipSelf,
    ViewChild,
} from '@angular/core';
import {
    AbstractControl,
    ControlContainer,
    ControlValueAccessor,
    FormControl,
    NG_VALUE_ACCESSOR,
} from '@angular/forms';
import * as $ from 'jquery';

export interface ValidationMessages {
    [key: string]: string | ((control: AbstractControl) => string);
}

@Component({
    selector: 'app-forms-input',
    templateUrl: './input.component.html',
    styleUrls: ['./input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => InputComponent),
            multi: true,
        },
    ],
})
export class InputComponent implements OnInit, AfterViewInit, ControlValueAccessor {
    @ViewChild('input') inputElement: ElementRef;

    @Input() color: 'white' | 'black' | 'gray' = 'black';
    @Input() label?: string;
    @Input() formControlName: string;
    @Input() placeholder?: string;
    @Input() hint?: string;
    @Input() icon?: string;
    @Input() type: 'text' | 'password' | 'email' | 'number' = 'text';
    @Input() validationMessages: ValidationMessages;
    @Input() inputMode: 'text' | 'numeric' | 'tel' | 'email' = 'text';
    @Input() autofocus = false;
    @Input() center = false;
    @Input() small = false;
    @Input() showClear = false;
    @Input() modal = false;

    control: FormControl;
    passwordVisible = false;
    forceType: string;

    propagateChange: (_: string) => void;
    propagateTouch: () => void;

    constructor(
        @Optional() @Host() @SkipSelf() private controlContainer: ControlContainer,
        private cdr: ChangeDetectorRef,
    ) {}

    ngOnInit(): void {
        if (this.controlContainer) {
            this.control = this.controlContainer.control.get(this.formControlName) as FormControl;
        }
    }

    ngAfterViewInit(): void {
        if (this.autofocus) {
            setTimeout(() => {
                $(this.inputElement.nativeElement).trigger('focus');
            }, 400);
        }
    }

    registerOnChange(fn: (_: string) => void): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.propagateTouch = fn;
    }

    writeValue(value: string): void {
        if (value) {
            this.control?.markAsTouched();
        }
    }

    togglePasswordVisibility(): void {
        if (this.passwordVisible) {
            this.forceType = null;
        } else {
            this.forceType = 'text';
        }
        this.passwordVisible = !this.passwordVisible;
    }

    setDisabledState(isDisabled: boolean): void {
        isDisabled ? this.control?.disable() : this.control?.enable();
    }

    clear(): void {
        this.control.setValue('');
    }
}
