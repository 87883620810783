import { CheckboxComponent } from 'src/app/shared/components/forms/checkbox/checkbox.component';
import { ChoiceComponent } from 'src/app/shared/components/forms/choice/choice.component';
import { InputComponent } from 'src/app/shared/components/forms/input/input.component';
import { PhoneNumberComponent } from 'src/app/shared/components/forms/phone-number/phone-number.component';
import { ValidationCodeComponent } from 'src/app/shared/components/forms/validation-code/validation-code.component';

export const FORM_COMPONENTS = [
    InputComponent,
    ChoiceComponent,
    CheckboxComponent,
    PhoneNumberComponent,
    ValidationCodeComponent,
];
