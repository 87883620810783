import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    Output,
    ViewChild,
} from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { GamesService } from '../../../../services/lopp-api/game/index.service';
import { ToastService } from '../../../../services/toast/index.service';

import {
    Plugins,
    CameraResultType,
    CameraPhoto,
    CameraSource,
    PermissionType,
} from '@capacitor/core';
import { Utils } from '../../utils';
import { AnalyticsService } from 'src/services/firebase/analytics/index.service';
import { takeUntil } from 'rxjs/operators';
import { OpenNativeSettings } from '@ionic-native/open-native-settings/ngx';
import { ModalService } from 'src/services/modal.service';
import { NetworkService } from 'src/services/network.service';
import { GalleryModal } from 'src/app/restaurants/modals/gallery/gallery.modal';
import { GameEarningResponseDto } from 'src/services/lopp-api/game/index.dtos';
const { Camera, Permissions } = Plugins;

@Component({
    selector: 'app-shared-bill-selector',
    template: `
        <app-shared-bottom-panel
            [withTabBar]="withTabBar"
            [(visible)]="visible"
            (visibleChange)="visibleChange.emit(visible)"
            [hideOnOutsideClick]="true"
        >
            <div class="BillSelector BillSelector--noMarginTop" *ngIf="this.game">
                <app-shared-selector-item
                    (click)="uploadBill('CAMERA', this.game.id)"
                    title="wallet.rescan_bill"
                    icon="camera"
                ></app-shared-selector-item>
                <app-shared-selector-item
                    (click)="showBill()"
                    title="wallet.view_bill"
                    icon="select_picture"
                ></app-shared-selector-item>
            </div>
        </app-shared-bottom-panel>
    `,
})
export class BillSelectorComponent implements AfterViewInit, OnDestroy {
    @ViewChild('webFile') webFile: ElementRef;

    @Input() visible: boolean;
    @Input() selected?: Observable<GameEarningResponseDto>;
    @Input() withTabBar: boolean;

    @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() billUploaded: EventEmitter<void> = new EventEmitter<void>();

    game: GameEarningResponseDto;
    onDestroy$ = new Subject<void>();

    constructor(
        private toast: ToastService,
        private translate: TranslateService,
        private loadingController: LoadingController,
        private gamesService: GamesService,
        private analytics: AnalyticsService,
        private openNativeSettings: OpenNativeSettings,
        private modalService: ModalService,
        private networkService: NetworkService,
    ) {}

    ngAfterViewInit(): void {
        if (this.selected) {
            this.selected.pipe(takeUntil(this.onDestroy$)).subscribe(async (game) => {
                this.game = game;
            });
        }
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    showBill(): void {
        this.modalService.openIonModal({
            component: GalleryModal,
            componentProps: {
                images: [this.game.billUrl],
                activeIndex: 0,
            },
            swipeToClose: true,
        });
    }

    async uploadBill(type: 'PICTURE' | 'CAMERA', gameId: string): Promise<void> {
        if (!(await this.networkService.checkNetWorkStatus())) {
            return;
        }

        if (type === 'CAMERA') {
            const { state } = await Permissions.query({ name: PermissionType.Camera });
            if (state === 'denied') {
                await this.modalService.alert({
                    title: 'common.errors.camera_disabled.title',
                    text: 'common.errors.camera_disabled.text',
                    confirmTxt: 'common.errors.camera_disabled.button_text',
                });
                this.openNativeSettings.open('application_details');
            }
        }

        const loading = await this.loadingController.create({
            spinner: 'crescent',
            mode: 'ios',
        });
        await loading.present();

        let image: CameraPhoto;
        try {
            image = await Camera.getPhoto({
                quality: 90,
                width: 1000,
                source: type === 'CAMERA' ? CameraSource.Camera : CameraSource.Photos,
                resultType: CameraResultType.DataUrl,
            });
        } catch (error) {
            console.log('error', error);
            // picture cancelled from user
            await loading.dismiss();
            this.visible = false;
            this.visibleChange.emit(this.visible);

            return;
        }

        if (image) {
            try {
                const file = Utils.dataURItoBlob(image.dataUrl);

                await this.gamesService.uploadBill(gameId, file);

                await Utils.wait(1000);
                await this.toast.present({
                    color: 'success',
                    duration: 4000,
                    message: this.translate.instant('wallet.bill_uploaded'),
                });

                const {
                    restaurantName: restaurant_name,
                    restaurantId: restaurant_id,
                    amount: billAmount,
                } = await this.gamesService.getGame(gameId);

                this.analytics.logEvent('bill_entered', {
                    restaurant_name,
                    restaurant_id,
                    billAmount,
                });

                this.billUploaded.emit();
            } catch (error) {
                await this.toast.present({
                    message: this.translate.instant('common.errors.send_data'),
                    error,
                });
            } finally {
                await loading.dismiss();
                this.visible = false;
                this.visibleChange.emit(this.visible);
            }
        }
    }
}
