import { Injectable } from '@angular/core';
import { Plugins } from '@capacitor/core';
import { ToastService } from 'src/services/toast/index.service';
import { TranslateService } from '@ngx-translate/core';
import { UsersService } from 'src/services/lopp-api/user/user.service';
import { Store } from '@ngrx/store';
import { AuthService } from 'src/services/firebase/auth/auth.service';
import firebase from 'firebase/app';
import { User } from '@codetrix-studio/capacitor-google-auth/dist/esm/user';
import { AuthState } from 'src/stores/auth/index.state';
import { NavController } from '@ionic/angular';
import { RegistrationActions } from 'src/stores/registration/registration.actions';
import { SignupService } from 'src/services/signup.service';
const { GoogleAuth } = Plugins;

@Injectable()
export class GoogleLoginService {
    constructor(
        private usersService: UsersService,
        private store: Store<{ auth: AuthState }>,
        private authService: AuthService,
        private navCtrl: NavController,
        private signupService: SignupService,
    ) {}

    async login(): Promise<boolean> {
        const {
            credential,
            googleUser: { email },
        } = await this._getCredentialAndGoogleUser();

        if (!credential) {
            return false;
        }

        if (!(await this.signupService.checkEmail(email, 'google.com'))) {
            return false;
        }

        const { user, additionalUserInfo } = await this.authService
            .instance()
            .signInWithCredential(credential);

        const idToken = await user.getIdToken();
        const hasAccount = await this.usersService.hasAccount(idToken);
        if (hasAccount) {
            this.navCtrl.navigateRoot('/home', { animationDirection: 'forward' });

            return true;
        }

        const profile = additionalUserInfo.profile as {
            given_name: string;
            family_name: string;
            picture: string;
        };

        this.store.dispatch(
            RegistrationActions.googleRegistration({
                email,
                firstName: profile.given_name,
                lastName: profile.family_name,
                idToken,
                imageUrl: profile.picture,
            }),
        );
        this.navCtrl.navigateForward(['/registration/register']);

        return false;
    }

    private async _getCredentialAndGoogleUser(): Promise<{
        credential: firebase.auth.OAuthCredential;
        googleUser: User;
    }> {
        const googleUser = (await GoogleAuth.signIn()) as User;
        const credential = firebase.auth.GoogleAuthProvider.credential(
            googleUser.authentication.idToken,
        );

        return { credential, googleUser };
    }
}
