import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AuthState } from 'src/stores/auth/index.state';
import { AppState } from 'src/stores/root.reducer';

const selectAuth = createFeatureSelector<AppState, AuthState>('auth');

const selectIsSigningUp = createSelector(selectAuth, ({ isSigningUp }) => isSigningUp);
const selectFirstName = createSelector(selectAuth, ({ firstName }) => firstName);
const selectIsAmbassador = createSelector(selectAuth, ({ isAmbassador }) => isAmbassador);
const selectLanguage = createSelector(selectAuth, ({ language }) => language);
const selectLopps = createSelector(selectAuth, ({ lopps }) => lopps);

export const AuthSelectors = {
    selectIsSigningUp,
    selectFirstName,
    selectIsAmbassador,
    selectLanguage,
    selectLopps,
};
