import { Component, OnInit, ViewChild } from '@angular/core';
import { AlertModalComponent } from 'src/app/shared/components/alert-modal/alert-modal.component';
import { UsersService } from '../../../../services/lopp-api/user/user.service';

import { Plugins } from '@capacitor/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from '../../../../services/toast/index.service';
import { Platform } from '@ionic/angular';
import { AnalyticsService } from 'src/services/firebase/analytics/index.service';
import { RemoteConfigService } from 'src/services/firebase/remote-config/index.service';
const { Share } = Plugins;

@Component({
    selector: 'app-shared-invite-your-friends-modal',
    templateUrl: './invite-your-friends-modal.component.html',
    styleUrls: ['./invite-your-friends-modal.component.scss'],
})
export class InviteYourFriendsModalComponent implements OnInit {
    @ViewChild('modal') modal: AlertModalComponent;

    isLoading = false;
    referalCode: string;
    referralLopps: number;

    constructor(
        private usersService: UsersService,
        private translate: TranslateService,
        private toast: ToastService,
        private platform: Platform,
        private analytics: AnalyticsService,
        private remoteConfig: RemoteConfigService,
    ) {}

    async ngOnInit(): Promise<void> {
        if (this.isLoading) return;
        this.isLoading = true;

        this.referralLopps = await this.remoteConfig.getNumber('REFERRAL_LOPPS');

        try {
            const { referralCode } = await this.usersService.profile();
            this.referalCode = referralCode.toUpperCase();
            this.isLoading = false;
        } catch (error) {
            await this.toast.present({
                message: this.translate.instant('common.errors.get_data'),
                error,
            });
        }
    }

    async submit(): Promise<void> {
        if (this.isLoading || !this.referalCode) return;
        this.isLoading = true;

        this.analytics.logEvent('share');

        try {
            if (this.platform.is('capacitor')) {
                await Share.share({
                    title: this.translate.instant('home.invite_your_friends.share_content.title'),
                    text:
                        this.translate.instant('home.invite_your_friends.share_content.text') +
                        this.referalCode,
                    url: 'https://www.lopp.fr/', // TODO: add link to store
                    dialogTitle: this.translate.instant('home.invite_your_friends.dialogTitle'),
                });
            } else {
                console.log('> only for native environment');
            }
        } catch (error) {
            await this.toast.present({
                message: this.translate.instant('common.errors.send_data'),
                error,
            });
        } finally {
            this.isLoading = false;
        }
    }
}
