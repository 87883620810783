import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';
import { GeolocationService } from 'src/services/geolocation';

@Component({
    selector: 'app-shared-location-disabled',
    templateUrl: './location-disabled.component.html',
    styleUrls: ['./location-disabled.component.scss'],
})
export class LocationDisabledComponent {
    isLoading = false;
    isProduction = environment.production;

    constructor(private geolocationService: GeolocationService) {}

    async allow(): Promise<void> {
        this.isLoading = true;
        await this.geolocationService.update(true);
        this.isLoading = false;
    }

    debugMode(): void {
        this.geolocationService.debugMode();
    }
}
