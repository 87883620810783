import { Component, Input, OnInit } from '@angular/core';
import { InviteYourFriendsModalComponent } from 'src/app/shared/components/invite-your-friends-modal/invite-your-friends-modal.component';
import { ModalService } from 'src/services/modal.service';
import { RemoteConfigService } from '../../../../services/firebase/remote-config/index.service';
import { SignupModal } from '../../../login/modals/signup/signup.modal';

@Component({
    selector: 'app-shared-invite-your-friends',
    templateUrl: './invite-your-friends.component.html',
    styleUrls: ['./invite-your-friends.component.scss'],
})
export class InviteYourFriendsComponent implements OnInit {
    @Input() isLogged = false;

    earnedLoppValue = '-';

    constructor(private modalService: ModalService, private remoteConfig: RemoteConfigService) {}

    async ngOnInit(): Promise<void> {
        try {
            this.earnedLoppValue = (
                (await this.remoteConfig.getNumber('REFERRAL_LOPPS')) || 3
            ).toString();
        } catch (error) {
            // silent fail
        }
    }

    openModal(): void {
        if (!this.isLogged) {
            this.modalService.openIonModal({ component: SignupModal });

            return;
        }

        this.modalService.openModal(InviteYourFriendsModalComponent);
    }
}
