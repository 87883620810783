import { Injectable } from '@angular/core';
import { Location } from 'src/services/geolocation';
import { AutocompleteDto } from './index.dtos';

@Injectable()
export class GooglePlacesService {
    constructor() {}

    async getPlaceLocation(placeId: string): Promise<Location> {
        return new Promise((resolve) => {
            const map = new google.maps.Map(document.createElement('map'));

            const service = new google.maps.places.PlacesService(map);
            service.getDetails({ placeId, fields: ['geometry.location'] }, (result, status) => {
                const { location } = result.geometry;
                resolve({ lat: location.lat(), lng: location.lng() });
            });
        });
    }

    /**
     * Get autocomplete list of address
     */
    autocomplete(keyword: string): Promise<AutocompleteDto[]> {
        const service = new google.maps.places.AutocompleteService();

        const request = {
            input: keyword,
            componentRestrictions: { country: 'FR' },
        };

        return new Promise((resolve) => {
            service.getPlacePredictions(request, (results, status) => {
                if (status !== 'OK') return resolve([]);

                const addresses = results.map((result) => {
                    const address: AutocompleteDto = {
                        description: result.description,
                        placeId: result.place_id,
                    };

                    return address;
                });

                resolve(addresses);
            });
        });
    }
}
