import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import {
    LanguageDto,
    UserResponseDto,
    UpdateProfileDto,
    AuthSourceResponseDto,
    RequestOTPResponseDto,
    RequestOTPDto,
    RegisterDto,
    Language,
    EmailExistsDto,
} from './user.dtos';

enum Endpoint {
    Register = '/register',
    Profile = '/profile',
    Avatar = '/avatar',
    Language = '/language',
    EnableNotification = '/enable-notification',
    IsNotificationEnabled = '/is-notification-enabled',
    AuthSource = '/auth-source',
    HasAccount = '/has-account',
    RequestOTP = '/request-otp',
    V2RequestOTP = '/request-otp/v2',
    CheckOTP = '/check-otp',
    SetPhoneNumber = '/phone-number',
    EmailExists = '/email-exists',
}

@Injectable()
export class UsersService {
    baseUrl = environment.server.baseUri + '/user';

    constructor(private http: HttpClient) {}

    /**
     * Create new account
     */
    async register(dto: RegisterDto): Promise<UserResponseDto> {
        return this.http.post<UserResponseDto>(this.baseUrl + Endpoint.Register, dto).toPromise();
    }

    /**
     * Get profile detail
     */
    async profile(): Promise<UserResponseDto> {
        return this.http.get<UserResponseDto>(this.baseUrl + Endpoint.Profile).toPromise();
    }

    /**
     * Update profile
     */
    async updateProfile(dto: UpdateProfileDto): Promise<void> {
        await this.http.put(this.baseUrl + Endpoint.Profile, dto).toPromise();
    }

    /**
     * Update avatar
     */
    async updateAvatar(file: File | Blob): Promise<string> {
        const data = new FormData();
        data.append('file', file);

        const { url } = await this.http
            .put<{ url: string }>(this.baseUrl + Endpoint.Avatar, data)
            .toPromise();

        return url;
    }

    /**
     * Update language
     */
    async setLanguage(dto: LanguageDto): Promise<void> {
        await this.http.put(this.baseUrl + Endpoint.Language, dto).toPromise();
    }

    /**
     * Trigger notification
     */
    async enableNotification(enable: boolean): Promise<void> {
        await this.http
            .put(this.baseUrl + Endpoint.EnableNotification + '/' + enable, null)
            .toPromise();
    }

    /**
     * Is notification enabled
     */
    async isNotificationEnabled(): Promise<boolean> {
        return this.http.get<boolean>(this.baseUrl + Endpoint.IsNotificationEnabled).toPromise();
    }

    /**
     * Get auth source from e-mail
     */
    async authSource(email: string): Promise<AuthSourceResponseDto> {
        return this.http
            .get<AuthSourceResponseDto>(this.baseUrl + Endpoint.AuthSource + '/' + email)
            .toPromise();
    }

    /**
     * Check if user already has an account
     */
    async hasAccount(token: string): Promise<boolean> {
        return this.http.get<boolean>(this.baseUrl + Endpoint.HasAccount + '/' + token).toPromise();
    }

    requestOTP(phoneNumber: string, language: Language): Observable<RequestOTPResponseDto> {
        const dto: RequestOTPDto = { phoneNumber, language };

        return this.http.post<RequestOTPResponseDto>(this.baseUrl + Endpoint.RequestOTP, dto);
    }

    v2RequestOTP(phoneNumber: string, language: Language): Observable<RequestOTPResponseDto> {
        const dto: RequestOTPDto = { phoneNumber, language };

        return this.http.post<RequestOTPResponseDto>(this.baseUrl + Endpoint.V2RequestOTP, dto);
    }

    checkOTP(otpRequestId: string, code: number): Observable<boolean> {
        return this.http.get<boolean>(
            this.baseUrl + Endpoint.CheckOTP + '/' + otpRequestId + '/' + code,
        );
    }

    setPhoneNumber(otpRequestId: string, validationCode: number): Observable<UserResponseDto> {
        const endpoint = `${this.baseUrl}${Endpoint.SetPhoneNumber}/${otpRequestId}/${validationCode}`;

        return this.http.put<UserResponseDto>(endpoint, null);
    }

    emailExists(email: string): Observable<boolean> {
        const dto: EmailExistsDto = { email };
        const endpoint = `${this.baseUrl}${Endpoint.EmailExists}`;

        return this.http.post<boolean>(endpoint, dto);
    }
}
