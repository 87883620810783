import { Action } from '@ngrx/store';
import { Language } from '../../services/lopp-api/user/user.dtos';

export enum AuthActionType {
    SetIsSigningUp = '[AUTH] Set isSigningUp',
    Login = '[AUTH] Login',
    Logout = '[AUTH] Logout',
    Language = '[AUTH] Language',
    SetProfile = '[AUTH] Set profile',
    RemoveCredits = '[AUTH] Decrement credits quantity',
    AddCredits = '[AUTH] Increment credits quantity',
    AddPendingEarnings = '[AUTH] Increment pending earnings',
    UpdateEarnings = '[AUTH] Update earnings',
    UpdateProfileName = '[AUTH] Update profile name',
    UpdateProfileAvatar = '[AUTH] Update profile avatar',
}

export class AuthSetIsSigningUp implements Action {
    readonly type = AuthActionType.SetIsSigningUp;

    constructor(
        public payload: {
            isSigningUp: boolean;
        },
    ) {}
}

export class AuthLogin implements Action {
    readonly type = AuthActionType.Login;
}

export class AuthLogout implements Action {
    readonly type = AuthActionType.Logout;
}

export class AuthLanguage implements Action {
    readonly type = AuthActionType.Language;

    constructor(
        public payload: {
            language: Language;
        },
    ) {}
}

export class AuthSetProfile implements Action {
    readonly type = AuthActionType.SetProfile;

    constructor(
        public payload: {
            lopps: number;
            totalEarnings: number;
            totalPendingEarnings: number;
            firstName: string;
            lastName: string;
            avatar: string;
            isAmbassador: boolean;
        },
    ) {}
}

export class AuthRemoveCredits implements Action {
    readonly type = AuthActionType.RemoveCredits;

    constructor(
        public payload: {
            lopps: number;
        },
    ) {}
}

export class AuthAddCredits implements Action {
    readonly type = AuthActionType.AddCredits;

    constructor(
        public payload: {
            lopps: number;
        },
    ) {}
}

export class AuthAddPendingEarnings implements Action {
    readonly type = AuthActionType.AddPendingEarnings;

    constructor(
        public payload: {
            pendingEarnings: number;
        },
    ) {}
}

export class AuthUpdateEarnings implements Action {
    readonly type = AuthActionType.UpdateEarnings;

    constructor(
        public payload: {
            earnings: number;
            pendingEarnings: number;
        },
    ) {}
}

export class AuthUpdateProfileName implements Action {
    readonly type = AuthActionType.UpdateProfileName;

    constructor(
        public payload: {
            firstName: string;
            lastName: string;
        },
    ) {}
}

export class AuthUpdateProfileAvatar implements Action {
    readonly type = AuthActionType.UpdateProfileAvatar;

    constructor(
        public payload: {
            avatar: string;
        },
    ) {}
}

export type Actions =
    | AuthSetIsSigningUp
    | AuthLogin
    | AuthLogout
    | AuthLanguage
    | AuthSetProfile
    | AuthRemoveCredits
    | AuthAddCredits
    | AuthAddPendingEarnings
    | AuthUpdateEarnings
    | AuthUpdateProfileName
    | AuthUpdateProfileAvatar;
