import { createAction, props } from '@ngrx/store';
import { Location } from 'src/services/geolocation';

const domain = '[LOCATION]';

const enable = createAction(`${domain} Enable`);
const disable = createAction(`${domain} Disable`);
const debugMode = createAction(`${domain} Debug mode`);
const update = createAction(`${domain} Update`, props<{ coords: Location }>());

export const LocationActions = { enable, disable, debugMode, update };
