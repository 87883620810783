import { Component } from '@angular/core';
import { NavController } from '@ionic/angular';
import { ModalService } from 'src/services/modal.service';
import { InviteYourFriendsModalComponent } from 'src/app/shared/components/invite-your-friends-modal/invite-your-friends-modal.component';
import { select, Store } from '@ngrx/store';
import { AuthState } from 'src/stores/auth/index.state';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
    lopps: number;
    totalEarnings: number;

    constructor(
        private navCtrl: NavController,
        private modalService: ModalService,
        private store: Store<{ auth: AuthState }>,
    ) {}

    async ngOnInit(): Promise<void> {
        this.store.pipe(select('auth')).subscribe(({ lopps, totalEarnings, avatar }) => {
            this.lopps = lopps;
            this.totalEarnings = totalEarnings;
        });
    }

    gotoPurchase(): void {
        this.navCtrl.navigateForward('/profile/purchase-lopps');
    }

    openReferralModal(): void {
        this.modalService.openModal(InviteYourFriendsModalComponent);
    }
}
