import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-new-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
    @Input() color: 'black' | 'white' | 'yellow' | 'transparent' | 'link' = 'black';
    @Input() arrow: 'left' | 'right';
    @Input() isLoading = false;
    @Input() disabled = false;
    @Input() icon?: string;
    @Input() modal = false;

    @Output() onClick = new EventEmitter<void>();
    @Output() onDisabledClick = new EventEmitter<void>();

    click(): void {
        this.disabled ? this.onDisabledClick.emit() : this.onClick.emit();
    }
}
