import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AlertModalComponent } from 'src/app/shared/components/alert-modal/alert-modal.component';
import { environment } from 'src/environments/environment';
import { RemoteConfigService } from 'src/services/firebase/remote-config/index.service';

@Component({
    selector: 'app-login-referal-code-modal',
    templateUrl: './referal-code-modal.component.html',
})
export class ReferalCodeModalComponent implements OnInit {
    @Output() onSubmit: EventEmitter<string> = new EventEmitter<string>();

    @ViewChild('modal') modal: AlertModalComponent;

    form: FormGroup;
    referralLopps: number;

    constructor(private remoteConfig: RemoteConfigService) {}

    async ngOnInit(): Promise<void> {
        this.referralLopps = await this.remoteConfig.getNumber('REFERRAL_LOPPS');

        this.form = new FormGroup({
            code: new FormControl(null, Validators.required),
        });

        if (!environment.production) {
            this.form.patchValue(
                {
                    code: '',
                },
                { emitEvent: true },
            );
        }
    }

    submit(): void {
        this.modal.close();
        this.onSubmit.emit(this.form.get('code').value);
        this.onSubmit.complete();
    }

    skip(): void {
        this.modal.close();
        this.onSubmit.emit(null);
        this.onSubmit.complete();
    }

    close(): void {
        this.modal.close();
    }
}
