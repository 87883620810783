import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    ViewChild,
} from '@angular/core';
import { GestureController, GestureDetail, Platform } from '@ionic/angular';

@Component({
    selector: 'app-shared-bottom-panel',
    templateUrl: './bottom-panel.component.html',
    styleUrls: ['./bottom-panel.component.scss'],
})
export class BottomPanelComponent implements AfterViewInit {
    @Input() visible = false;
    @Input() hideOnOutsideClick = false;
    @Input() withTabBar = true;

    @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    @ViewChild('handle') handle: ElementRef;

    constructor(private gestureCtrl: GestureController, public platform: Platform) {}

    ngAfterViewInit(): void {
        const gesture = this.gestureCtrl.create(
            {
                gestureName: 'my-gesture',
                el: this.handle.nativeElement,
                direction: 'y',
                onStart: (detail: GestureDetail) => {
                    if (detail.deltaY > 0) {
                        this.hide();
                    }
                },
            },
            true,
        );

        gesture.enable();
    }

    hide(): void {
        this.visible = false;
        this.visibleChange.emit(this.visible);
    }
}
