import { createFeatureSelector, createSelector } from '@ngrx/store';
import { GameSurveyState } from 'src/stores/game-survey/game-survey.state';
import { AppState } from 'src/stores/root.reducer';

const selectState = createFeatureSelector<AppState, GameSurveyState>('gameSurvey');

const selectStep1 = createSelector(selectState, ({ scheduledOn }) => {
    return { scheduledOn };
});

const selectStep2 = createSelector(selectState, ({ amount }) => {
    return { amount };
});

const selectStep3 = createSelector(selectState, ({ restaurant }) => {
    return { restaurant };
});

const selectCurrentStep = createSelector(selectState, ({ currentStep }) => {
    return { currentStep };
});

const selectRestaurants = createSelector(selectState, ({ restaurants, lastLocation }) => {
    return { restaurants, lastLocation };
});

const selectPartnersHintSkipped = createSelector(selectState, ({ partnersHintSkipped }) => {
    return partnersHintSkipped;
});

const selectGame = createSelector(selectState, ({ game }) => {
    return game;
});

export const GameSurveySelectors = {
    selectState,
    selectStep1,
    selectStep2,
    selectStep3,
    selectRestaurants,
    selectCurrentStep,
    selectGame,
    selectPartnersHintSkipped,
};
