import {
    AfterViewInit,
    Component,
    forwardRef,
    Host,
    Input,
    OnDestroy,
    OnInit,
    Optional,
    SkipSelf,
} from '@angular/core';
import {
    AbstractControl,
    ControlContainer,
    ControlValueAccessor,
    FormControl,
    FormGroup,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
} from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as libPhoneNumber from 'google-libphonenumber';
import * as countryTelData from 'country-telephone-data';
import { ModalService } from 'src/services/modal.service';
import {
    SelectModalComponent,
    SelectOption,
} from 'src/app/shared/components/select-modal/select-modal.component';

@Component({
    selector: 'app-forms-phone-number',
    templateUrl: './phone-number.component.html',
    styleUrls: ['./phone-number.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => PhoneNumberComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: PhoneNumberComponent,
        },
    ],
})
export class PhoneNumberComponent
    implements OnInit, AfterViewInit, OnDestroy, ControlValueAccessor, Validator
{
    @Input() color: 'white' | 'black' = 'black';
    @Input() form: FormGroup;
    @Input() formControlName: string;
    @Input() icon: string;
    @Input() hint?: string;
    @Input() label?: string;
    @Input() center = false;

    control: FormControl;
    inputControl = new FormControl();
    countryCode: string;
    phoneNumber: libPhoneNumber.PhoneNumber;
    placeholder: string;
    errorMessages: string[] = [];
    countryCodeChanged = false;

    onDestroy$ = new Subject<void>();

    phoneNumberUtil = libPhoneNumber.PhoneNumberUtil.getInstance();

    propagateChange: (_: string) => void;
    propagateTouch: () => void;

    constructor(
        @Optional() @Host() @SkipSelf() private controlContainer: ControlContainer,
        private modalService: ModalService,
    ) {}

    ngOnInit(): void {
        if (this.controlContainer) {
            this.control = this.controlContainer.control.get(this.formControlName) as FormControl;
        }
        this._setCountryCode('33');
    }

    ngAfterViewInit(): void {
        this.inputControl?.valueChanges
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((value: string) => {
                if (!value) {
                    this.propagateChange(null);

                    return;
                }

                try {
                    this.phoneNumber = this.phoneNumberUtil.parse(`${this.countryCode}${value}`);
                    this.propagateChange(
                        this.phoneNumberUtil.format(
                            this.phoneNumber,
                            libPhoneNumber.PhoneNumberFormat.E164,
                        ),
                    );
                } catch {
                    this.phoneNumber = null;
                    this.propagateChange('invalid');
                }
            });
    }

    registerOnChange(fn: (_: string) => void): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.propagateTouch = fn;
    }

    writeValue(value: string): void {
        if (value) {
            try {
                this.phoneNumber = this.phoneNumberUtil.parse(value);
                this.countryCode = `+${this.phoneNumber.getCountryCode()}`;
                const nationalPhoneNumber = this.phoneNumberUtil.format(
                    this.phoneNumber,
                    libPhoneNumber.PhoneNumberFormat.NATIONAL,
                );
                this.inputControl.setValue(nationalPhoneNumber, { emitEvent: false });
                this.control.setValue(value, { emitEvent: false });
                setTimeout(() => {
                    this.propagateTouch();
                });
            } catch (e) {
                this.phoneNumber = null;
                this.inputControl.setValue(null, { emitEvent: false });
            }
        }
    }

    setDisabledState(isDisabled: boolean): void {
        isDisabled ? this.control?.disable() : this.control?.enable();
    }

    validate(control: AbstractControl): ValidationErrors | null {
        if (!control.value) {
            this.errorMessages = [];

            return null;
        }

        if (!this.phoneNumber || !this.phoneNumberUtil.isValidNumber(this.phoneNumber)) {
            const errors: ValidationErrors = {
                phoneNumber: 'invalid',
            };

            this.errorMessages = ['Numéro de téléphone invalide'];

            return errors;
        }

        this.errorMessages = [];

        return null;
    }

    async selectCountryCode(): Promise<void> {
        const options: SelectOption[] = [];
        for (const country of countryTelData.allCountries) {
            const { name, dialCode } = country;
            options.push({
                label: `${name} +${dialCode}`,
                value: dialCode,
            });
        }

        const instance = await this.modalService.openIonModal({
            component: SelectModalComponent,
            swipeToClose: true,
            componentProps: {
                options,
                title: 'Indicatif téléphonique',
            },
        });
        const { data } = await instance.onWillDismiss<SelectOption>();
        if (data) {
            this._setCountryCode(data.value);
            this.countryCodeChanged = true;
        }
    }

    private _setCountryCode(countryCode: string): void {
        this.countryCode = '+' + countryCode;
        this.placeholder = this.phoneNumberUtil.format(
            this.phoneNumberUtil.getExampleNumberForType(
                this.phoneNumberUtil.getRegionCodeForCountryCode(+countryCode),
                libPhoneNumber.PhoneNumberType.MOBILE,
            ),
            libPhoneNumber.PhoneNumberFormat.NATIONAL,
        );
        this.inputControl.reset();
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }
}
