import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import * as $ from 'jquery';

@Component({
    selector: 'app-shared-selector-item',
    templateUrl: './selector-item.component.html',
    styleUrls: ['./selector-item.component.scss'],
})
export class SelectorItemComponent implements AfterViewInit {
    @HostBinding('className') componentClass = 'ProfileMenuItem';

    @ViewChild('subItemsContent') subItemsContentElement: ElementRef;

    @Input() icon: string;
    @Input() title: string;
    @Input() subItems: string[];

    @Output() subItemClicked: EventEmitter<number> = new EventEmitter<number>();

    // tslint:disable-next-line: no-any
    jQueryContent: JQuery<any>;
    opened = false;

    constructor() {}

    ngAfterViewInit(): void {
        if (this.subItemsContentElement) {
            this.jQueryContent = $(this.subItemsContentElement.nativeElement);
        }
    }

    toggleContent(): void {
        if (!this.subItems) {
            return;
        }

        if (this.opened) {
            this.jQueryContent.slideUp();
        } else {
            this.jQueryContent.slideDown();
        }

        this.opened = !this.opened;
    }
}
