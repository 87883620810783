import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, mergeMap } from 'rxjs/operators';
import { RestaurantService } from 'src/services/lopp-api/restaurant/index.service';
import { LocationSelectors } from 'src/stores/location/index.selectors';
import { RestaurantsActions } from 'src/stores/restaurants/index.actions';

@Injectable()
export class RestaurantsEffects {
    /**
     * Load partners
     */

    loadPartners$ = createEffect(() =>
        this.actions$.pipe(
            ofType(RestaurantsActions.loadPartners),
            concatLatestFrom(() => this.store.select(LocationSelectors.selectCoords)),
            mergeMap(([_, { lat, lng }]) =>
                this.restaurantService
                    .getPartners({ lat, lng })
                    .pipe(map((partners) => RestaurantsActions.loadPartnersSuccess({ partners }))),
            ),
        ),
    );

    constructor(
        private actions$: Actions,
        private restaurantService: RestaurantService,
        private store: Store,
    ) {}
}
