import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AnalyticsService } from 'src/services/firebase/analytics/index.service';

@Component({
    selector: 'app-shared-buy-lopps-button',
    templateUrl: './buy-lopps-button.component.html',
    styleUrls: ['./buy-lopps-button.component.scss'],
})
export class BuyLoppsButtonComponent {
    @Output() buttonClicked: EventEmitter<void> = new EventEmitter<void>();

    constructor(private analytics: AnalyticsService) {}

    logEvent(): void {
        this.analytics.logEvent('buy_lopps_clicked');
    }
}
