import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-shared-header-badge',
    templateUrl: './header-badge.component.html',
    styleUrls: ['./header-badge.component.scss'],
})
export class HeaderBadgeComponent {
    @Input() title: string;
    @Input() value: string;
}
