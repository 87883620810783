import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { LocalStorageService } from '../../local-storage/index.service';
import {
    GamePlayedResponseDto,
    GameResponseDto,
    PlayGameType,
    EarningHistoryType,
    GameEarningResponseDto,
    CommunityEarningResponseDto,
    UserDailyPlayedResponseDto,
    StartGameDto,
} from './index.dtos';
import { Observable } from 'rxjs';

enum Endpoint {
    UserDailyPlayed = '/user-daily-played/',
    Start = '/start/v2',
    Active = '/active',
    Play = '/play/',
    Protect = '/protect/',
    Unprotect = '/unprotect/',
    Validate = '/validate/',
    EarningHistory = '/earning-history/',
    CommunityEarnings = '/community-earnings/',
    UploadBill = '/upload-bill/',
    GetGame = '/game/',
    Leave = '/leave/',
}

@Injectable()
export class GamesService {
    baseUrl = environment.server.baseUri + '/game';

    constructor(private http: HttpClient, private storage: LocalStorageService) {}

    /**
     * Get count of user daily game played
     */
    async userDailyPlayed(googlePlaceId: string): Promise<UserDailyPlayedResponseDto> {
        return this.http
            .get<UserDailyPlayedResponseDto>(
                this.baseUrl + Endpoint.UserDailyPlayed + googlePlaceId || '',
            )
            .toPromise();
    }

    /**
     * Start a new game
     */
    async start(dto: StartGameDto): Promise<GameResponseDto> {
        return this.http.post<GameResponseDto>(this.baseUrl + Endpoint.Start, dto).toPromise();
    }

    /**
     * Get active game session
     */
    async getActiveGame(): Promise<GameResponseDto> {
        return this.http.get<GameResponseDto>(this.baseUrl + Endpoint.Active).toPromise();
    }

    /**
     * Play on existing game
     */
    play(id: string, type: PlayGameType): Observable<GamePlayedResponseDto> {
        return this.http.put<GamePlayedResponseDto>(
            this.baseUrl + Endpoint.Play + id + '/' + type,
            null,
        );
    }

    /**
     * Protect a game session
     */
    protect(id: string): Observable<void> {
        return this.http.put<void>(this.baseUrl + Endpoint.Protect + id, null);
    }

    /**
     * Protect a game session
     */
    unprotect(id: string): Observable<void> {
        return this.http.put<void>(this.baseUrl + Endpoint.Unprotect + id, null);
    }

    /**
     * Validate a game session
     */
    async validate(id: string): Promise<GameResponseDto> {
        return this.http
            .put<GameResponseDto>(this.baseUrl + Endpoint.Validate + id, null)
            .toPromise();
    }

    /**
     * Get earning history by type
     */
    async earningHistory(type: EarningHistoryType): Promise<GameEarningResponseDto[]> {
        return this.http
            .get<GameEarningResponseDto[]>(this.baseUrl + Endpoint.EarningHistory + type)
            .toPromise();
    }

    /**
     * Get community earnings
     */
    communityEarnings(): Observable<CommunityEarningResponseDto> {
        const endpoint = `${this.baseUrl}${Endpoint.CommunityEarnings}`;

        return this.http.get<CommunityEarningResponseDto>(endpoint);
    }

    /**
     * Upload game bill
     */
    async uploadBill(id: string, file: File | Blob): Promise<void> {
        const data = new FormData();
        data.append('file', file);

        return this.http.post<void>(this.baseUrl + Endpoint.UploadBill + id, data).toPromise();
    }

    async getGame(id: string): Promise<GameResponseDto> {
        const endpoint = `${this.baseUrl}${Endpoint.GetGame}${id}`;

        return this.http.get<GameResponseDto>(endpoint).toPromise();
    }

    async leave(id: string): Promise<void> {
        const endpoint = `${this.baseUrl}${Endpoint.Leave}${id}`;

        return this.http.put<void>(endpoint, null).toPromise();
    }
}
