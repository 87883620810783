import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { IAPProduct, InAppPurchase2 } from '@ionic-native/in-app-purchase-2/ngx';
import { NavController, Platform } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { RemoteConfigService } from 'src/services/firebase/remote-config/index.service';
import { LoaderService } from 'src/services/loader.service';
import { LogService } from 'src/services/lopp-api/log/log.service';
import { PackageResponseDto } from 'src/services/lopp-api/package/index.dtos';
import { PackagesService } from 'src/services/lopp-api/package/index.service';
import { ModalService } from 'src/services/modal.service';
import { AuthAddCredits } from 'src/stores/auth/index.actions';
import { GameSurveyActions } from 'src/stores/game-survey/game-survey.actions';
import { GameSurveySelectors } from 'src/stores/game-survey/game-survey.selectors';

@Injectable()
export class InAppPurchaseService {
    packages: PackageResponseDto[] = [];
    update$ = new Subject<void>();
    promoCode: string;
    loppsAdded: number;
    i = 0;

    constructor(
        private packageService: PackagesService,
        private platform: Platform,
        private iap: InAppPurchase2,
        private store: Store,
        private router: Router,
        private ngZone: NgZone,
        private modalService: ModalService,
        private translate: TranslateService,
        private remoteConfig: RemoteConfigService,
        private logService: LogService,
        private navCtrl: NavController,
    ) {}

    async init(platform: 'ios' | 'android' | 'web'): Promise<void> {
        const bogoEnabled = (await this.remoteConfig.getNumber('BUY_ONE_GET_ONE')) === 1;

        this.iap.validator = async (product: IAPProduct, callback) => {
            if (product.type === 'application') {
                return callback(true);
            }

            try {
                const { id, currency, priceMicros, transaction } = product;
                if (platform === 'android') {
                    const { loppsAdded } = await this.packageService.purchaseAndroid({
                        id,
                        currency,
                        priceMicros,
                        transaction,
                        promoCode: this.promoCode,
                    });
                    this.loppsAdded = loppsAdded;
                } else if (platform === 'ios') {
                    const { loppsAdded } = await this.packageService.purchaseIOS({
                        id,
                        currency,
                        priceMicros,
                        transaction,
                        promoCode: this.promoCode,
                    });
                    this.loppsAdded = loppsAdded;
                }

                this.promoCode = null;

                console.log('validator succeeded');

                return callback(true);
            } catch (e) {
                let code = this.iap.INTERNAL_ERROR; /* Validation will retry with this code */

                this.promoCode = null;

                if (e instanceof HttpErrorResponse) {
                    const errorCode = e.error.error;
                    console.log('validator errorCode', errorCode);

                    if (errorCode === 'TRANSACTION_ALREADY_SUCCEEDED') {
                        code = this.iap.PURCHASE_EXPIRED;
                    } else if (errorCode === 'INVALID_RECEIPT') {
                        code = this.iap.PURCHASE_EXPIRED;
                    } else if (errorCode === 'SERVICE_UNAVAILABLE') {
                        code =
                            this.iap.CONNECTION_FAILED; /* Validation will retry with this code */
                    }
                }

                return callback(false, {
                    code,
                    error: { message: e.toString() },
                });
            }
        };

        this.packages = await this.packageService.list(platform === 'web' ? 'ios' : platform);

        if (platform === 'web') {
            this.packages = this.packages.map((item) => {
                item.visible = true;
                item.localizedPrice = '19,99€';
                item.title = '10 LOPPS';
                item.canPurchase = true;
                return item;
            });
            this.update$.next();
            return;
        }

        await this.platform.ready();

        for (const loppPackage of this.packages) {
            console.log('[IAP]', loppPackage);

            this.iap.register({
                id: loppPackage.productId,
                alias: loppPackage.productId,
                type: this.iap.CONSUMABLE,
            });

            this.iap.when(loppPackage.productId).updated((product: IAPProduct) => {
                console.log('[IAP] updated', product.alias, product.state, product.valid, product);

                this.logService.log(product);

                loppPackage.title = product.title;
                loppPackage.localizedPrice = product.price;
                loppPackage.price = product.priceMicros / 1000000;
                loppPackage.canPurchase = product.canPurchase;
                loppPackage.currency = product.currency;
                loppPackage.visible = product.state !== 'invalid';
                loppPackage.isLoading = ['initiated', 'approved'].includes(product.state);

                this.update$.next();
            });

            this.iap.when(loppPackage.productId).initiated((product: IAPProduct) => {});

            this.iap.when(loppPackage.productId).approved((product: IAPProduct) => {
                console.log('approved', product);

                product.verify();
            });

            this.iap.when(loppPackage.productId).verified((product: IAPProduct) => {
                console.log('verified', product);

                product.finish();
            });

            this.iap.when(loppPackage.productId).finished((product: IAPProduct) => {
                console.log('finished', product);
                this.store.dispatch(
                    new AuthAddCredits({ lopps: loppPackage.lopps * (bogoEnabled ? 2 : 1) }),
                );
                this.modalService.alert({
                    text: this.translate.instant('profile.purchase_lopps.success.text', {
                        value: this.loppsAdded,
                    }),
                    confirmTxt: 'profile.purchase_lopps.success.button',
                });
                this.ngZone.run(() => {
                    this.store
                        .select(GameSurveySelectors.selectGame)
                        .pipe(take(1))
                        .subscribe((game) => {
                            if (game) {
                                this.navCtrl.navigateBack(['/game', 'survey']);
                            } else {
                                this.navCtrl.navigateBack(['/profile']);
                            }
                        });
                });
            });
        }

        this.iap.refresh();
    }

    async order(loppPackage: PackageResponseDto, promoCode?: string): Promise<void> {
        this.promoCode = promoCode;
        this.iap.order(loppPackage.productId);
    }
}
