import { createAction, props } from '@ngrx/store';
import { Location } from 'src/services/geolocation';
import { GameResponseDto, ScheduledOn } from 'src/services/lopp-api/game/index.dtos';
import { RestaurantResponseDto } from 'src/services/lopp-api/restaurant/index.dtos';

const domain = '[GAME SURVEY]';

const setStep1 = createAction(`${domain} Set step 1`, props<{ scheduledOn: ScheduledOn }>());
const setStep2 = createAction(`${domain} Set step 2`, props<{ amount: number }>());
const setStep3 = createAction(
    `${domain} Set step 3`,
    props<{ restaurant: RestaurantResponseDto }>(),
);
const setRestaurants = createAction(
    `${domain} Set restaurants`,
    props<{
        items: RestaurantResponseDto[];
        page: number;
        nextPageToken: string;
        lastLocation: Location;
    }>(),
);
const skipPartnersHint = createAction(`${domain} Skip partners hint partnersHintSkipped`);
const setGame = createAction(`${domain} Set game`, props<{ game: GameResponseDto }>());
const reset = createAction(`${domain} Reset`);

export const GameSurveyActions = {
    setStep1,
    setStep2,
    setStep3,
    setRestaurants,
    skipPartnersHint,
    setGame,
    reset,
};
