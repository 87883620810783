import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AuthService } from 'src/services/firebase/auth/auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(private navCtrl: NavController, private auth: AuthService) {}

    canActivate(): Observable<boolean> {
        return this.auth.instance().authState.pipe(
            tap((user) => {
                if (!user) {
                    this.navCtrl.navigateRoot('/registration');
                }
            }),
            map((user) => !!user),
        );
    }
}
