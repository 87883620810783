import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import '@capacitor-community/firebase-analytics';

import { Plugins, Capacitor } from '@capacitor/core';

const { FirebaseAnalytics, FacebookAnalytics } = Plugins;

@Injectable()
export class AnalyticsService {
    async logEvent(eventName: string, eventParams?: { [key: string]: any }): Promise<void> {
        if (environment.enableAnalytics && Capacitor.isPluginAvailable('FirebaseAnalytics')) {
            console.log('***', eventName, eventParams, '***');
            FirebaseAnalytics.logEvent({ name: eventName, params: eventParams });
            await FacebookAnalytics.logEvent({ event: eventName, params: eventParams });
        }
    }
}
