import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LoginModal } from '../login/login.modal';
import { ModalService } from 'src/services/modal.service';
import { ToastService } from '../../../../services/toast/index.service';
import { TranslateService } from '@ngx-translate/core';
import { select, Store } from '@ngrx/store';
import { UsersService } from '../../../../services/lopp-api/user/user.service';
import { Language } from '../../../../services/lopp-api/user/user.dtos';
import {
    RemoteConfigKeyString,
    RemoteConfigService,
} from '../../../../services/firebase/remote-config/index.service';
import { AuthState } from '../../../../stores/auth/index.state';
import { Plugins, Capacitor } from '@capacitor/core';
import { Utils } from 'src/app/shared/utils';
import { NetworkService } from 'src/services/network.service';
import { FacebookLoginService } from 'src/services/facebook-login.service';
import { GoogleLoginService } from 'src/services/google-login.service';
import { SignupService } from 'src/services/signup.service';
import { LoaderService } from 'src/services/loader.service';
import { environment } from 'src/environments/environment';
import { AppleLoginService } from 'src/services/apple-login.service';
import { AuthSetIsSigningUp } from 'src/stores/auth/index.actions';
import { AuthService } from 'src/services/firebase/auth/auth.service';
const { Browser } = Plugins;

@Component({
    templateUrl: './signup.modal.html',
})
export class SignupModal implements OnInit {
    language: Language;
    form: FormGroup;
    showPassword = false;

    isLoading = false;
    platform = Capacitor.platform;

    constructor(
        private modalService: ModalService,
        private toast: ToastService,
        private translate: TranslateService,
        private store: Store<{ auth: AuthState }>,
        private usersService: UsersService,
        private remoteConfig: RemoteConfigService,
        private networkService: NetworkService,
        private facebookLoginService: FacebookLoginService,
        private googleLoginService: GoogleLoginService,
        private appleLoginService: AppleLoginService,
        private signupService: SignupService,
        private loader: LoaderService,
        private authService: AuthService,
    ) {}

    ngOnInit(): void {
        this.store.pipe(select('auth')).subscribe(({ language }) => {
            this.language = language;
        });

        this.form = new FormGroup({
            firstName: new FormControl(null, Validators.required),
            lastName: new FormControl(null, Validators.required),
            email: new FormControl(null, [Validators.required, Validators.email]),
            password: new FormControl(null, Validators.required),
        });

        if (!environment.production) {
            this.form.patchValue(
                {
                    firstName: 'Someone',
                    lastName: 'Hasaname',
                    email: 'someonex20@gmail.com',
                    phoneNumber: '+33628926371',
                    password: '123456789',
                },
                { emitEvent: true },
            );
        }
    }

    async appleLogin(): Promise<void> {
        if (this.isLoading) return;

        if (!(await this.networkService.checkNetWorkStatus())) {
            return;
        }

        this.isLoading = true;
        this.loader.present();

        try {
            if (await this.appleLoginService.login()) {
                this.modalService.dismissIonModal();
            }
            this.loader.dismiss();
            this.isLoading = false;
        } catch (error) {
            console.log('error', error);
            this.isLoading = false;
            this.loader.dismiss();
            this.toast.present({
                message: this.translate.instant('common.errors.get_data'),
                error,
            });
            this.store.dispatch(new AuthSetIsSigningUp({ isSigningUp: false }));
        } finally {
            this.store.dispatch(new AuthSetIsSigningUp({ isSigningUp: false }));
        }
    }

    async facebookLogin(): Promise<void> {
        if (this.isLoading) return;

        if (!(await this.networkService.checkNetWorkStatus())) {
            return;
        }

        this.isLoading = true;
        this.loader.present();

        try {
            if (await this.facebookLoginService.login()) {
                this.modalService.dismissIonModal();
            }
            this.loader.dismiss();
            this.isLoading = false;
        } catch (error) {
            this.isLoading = false;
            this.loader.dismiss();
            this.toast.present({
                message: this.translate.instant('common.errors.get_data'),
                error,
            });
            this.store.dispatch(new AuthSetIsSigningUp({ isSigningUp: false }));
        } finally {
            this.store.dispatch(new AuthSetIsSigningUp({ isSigningUp: false }));
        }
    }

    async googleLogin(): Promise<void> {
        if (this.isLoading) return;

        if (!(await this.networkService.checkNetWorkStatus())) {
            return;
        }

        this.isLoading = true;
        this.loader.present();

        try {
            if (await this.googleLoginService.login()) {
                this.modalService.dismissIonModal();
            }
            this.loader.dismiss();
            this.isLoading = false;
        } catch (error) {
            this.isLoading = false;
            this.loader.dismiss();
            this.toast.present({
                message: this.translate.instant('common.errors.get_data'),
                error,
            });
            this.store.dispatch(new AuthSetIsSigningUp({ isSigningUp: false }));
        } finally {
            this.store.dispatch(new AuthSetIsSigningUp({ isSigningUp: false }));
        }
    }

    login(): void {
        this.modalService.dismissIonModal();
        this.modalService.openIonModal({ component: LoginModal });
    }

    async termsOfServices(): Promise<void> {
        try {
            const key: RemoteConfigKeyString = ('GENERAL_CONDITIONS_URL_' +
                this.language) as RemoteConfigKeyString;
            const termsUrl = await this.remoteConfig.getString(key);

            if (termsUrl) {
                await Browser.open({ url: termsUrl });
            }
        } catch (error) {
            await this.toast.present({
                message: this.translate.instant('common.errors.default'),
                error,
            });
        }
    }

    async privacyPolicy(): Promise<void> {
        try {
            const key: RemoteConfigKeyString = ('PRIVACY_POLICY_URL_' +
                this.language) as RemoteConfigKeyString;
            const privacyUrl = await this.remoteConfig.getString(key);

            if (privacyUrl) {
                await Browser.open({ url: privacyUrl });
            }
        } catch (error) {
            await this.toast.present({
                message: this.translate.instant('common.errors.default'),
                error,
            });
        }
    }

    async submit(): Promise<void> {
        if (this.isLoading) return;

        if (!(await this.networkService.checkNetWorkStatus())) {
            return;
        }

        this.isLoading = true;

        const { email, password, firstName, lastName } = this.form.value;
        const authSource = await this.usersService.authSource(email);
        if (authSource && authSource.value !== 'EMAIL') {
            this.toast.present({
                message: this.translate.instant('common.errors.signup_wrong_provider', {
                    provider: Utils.capitalize(authSource.value),
                }),
            });

            this.isLoading = false;

            return;
        }

        this.loader.present();
        const token = await this.signupService.registerUserToFirebase(email, password);
        if (!token) {
            this.loader.dismiss();
            this.isLoading = false;

            return;
        }

        // if (
        //     await this.signupService.registerUserToServer('email', token, firstName, lastName, null)
        // ) {
        //     this.modalService.dismissIonModal();
        // }

        this.isLoading = false;
        this.loader.dismiss();
    }
}
