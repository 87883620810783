import { Component, OnDestroy, OnInit } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { AnalyticsService } from 'src/services/firebase/analytics/index.service';
import { ModalService } from 'src/services/modal.service';
import { AuthState } from 'src/stores/auth/index.state';

type Path = '/home' | '/wallet' | '/profile';

@Component({
    selector: 'app-shared-new-tab-bar',
    templateUrl: './new-tab-bar.component.html',
    styleUrls: ['new-tab-bar.component.scss'],
})
export class NewTabBarComponent implements OnInit, OnDestroy {
    storeSub: Subscription;
    isLogged = false;
    url: string;
    activeItem: Path = '/home';

    constructor(
        private router: Router,
        private store: Store<{ auth: AuthState }>,
        private modalService: ModalService,
        private navCtrl: NavController,
        private analytics: AnalyticsService,
    ) {}

    ngOnInit(): void {
        this.storeSub = this.store.pipe(select('auth')).subscribe(({ isLogged }) => {
            this.isLogged = isLogged;
        });

        this.router.events.subscribe((event: Event) => {
            if (event instanceof NavigationEnd) {
                this.url = event.url;

                const tabs: { path: Path; index: number }[] = [
                    { path: '/home', index: 1 },
                    // { path: '/restaurants', index: 2 },
                    { path: '/wallet', index: 2 },
                    { path: '/profile', index: 3 },
                ];

                for (const tab of tabs) {
                    if (this.url.startsWith(tab.path)) {
                        this.activeItem = tab.path;
                    }
                }
            }
        });
    }

    ngOnDestroy(): void {
        if (this.storeSub) {
            this.storeSub.unsubscribe();
        }
    }

    goto(path: Path): void {
        if (path !== this.activeItem) {
            this.analytics.logEvent('navigation_changed', { page: path.slice(1) });
        }

        this.navCtrl.navigateRoot([path]);
    }

    isActive(path: string): boolean {
        return this.url && this.url.startsWith(path);
    }
}
