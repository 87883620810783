import { Actions, AuthActionType } from './index.actions';
import { AuthState } from './index.state';

export const initialState: AuthState = {
    isSigningUp: false,
    isLogged: false,
    language: null,
    lopps: 0,
    totalEarnings: 0,
    totalPendingEarnings: 0,
    firstName: '',
    lastName: '',
    avatar: null,
    isAmbassador: false,
};

export const AuthReducer = (state: AuthState = initialState, action: Actions): AuthState => {
    switch (action.type) {
        case AuthActionType.SetIsSigningUp:
            return { ...state, ...action.payload };
        case AuthActionType.Login:
            return { ...state, isLogged: true };
        case AuthActionType.Logout:
            return { ...initialState };
        case AuthActionType.Language:
            return { ...state, ...action.payload };
        case AuthActionType.SetProfile:
            return { ...state, ...action.payload };
        case AuthActionType.RemoveCredits:
            return { ...state, lopps: state.lopps - action.payload.lopps };
        case AuthActionType.AddCredits:
            return { ...state, lopps: state.lopps + action.payload.lopps };
        case AuthActionType.AddPendingEarnings:
            return {
                ...state,
                totalPendingEarnings: state.totalPendingEarnings + action.payload.pendingEarnings,
            };
        case AuthActionType.UpdateEarnings:
            return {
                ...state,
                totalEarnings: action.payload.earnings,
                totalPendingEarnings: action.payload.pendingEarnings,
            };
        case AuthActionType.UpdateProfileName:
            return {
                ...state,
                firstName: action.payload.firstName,
                lastName: action.payload.lastName,
            };
        case AuthActionType.UpdateProfileAvatar:
            return {
                ...state,
                avatar: action.payload.avatar,
            };
        default:
            return state;
    }
};
