import { Injectable } from '@angular/core';
import { Plugins } from '@capacitor/core';
import { Language } from 'src/services/lopp-api/user/user.dtos';
const { Device } = Plugins;

@Injectable()
export class DeviceService {
    async getLanguage(): Promise<Language> {
        const { value } = await Device.getLanguageCode();
        const [locale] = value.split('-');
        return locale === 'fr' ? 'FR' : 'EN';
    }
}
