import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IonSlides, NavController } from '@ionic/angular';
import { LocalStorageService } from '../../services/local-storage/index.service';
import { TranslateService } from '@ngx-translate/core';
import { ModalService } from 'src/services/modal.service';
import { ToastService } from '../../services/toast/index.service';
import { AnalyticsService } from 'src/services/firebase/analytics/index.service';

interface Slide {
    index: number;
    image: string;
    title: string;
    text: string;
}

@Component({
    templateUrl: './tutorial.modal.html',
    styleUrls: ['./tutorial.modal.scss'],
})
export class TutorialModal implements OnInit {
    @ViewChild(IonSlides) protected slider: IonSlides;

    @Input() unregisteredMode = false;

    slides: Slide[];
    activeIndex = 0;

    constructor(
        private analytics: AnalyticsService,
        private modalService: ModalService,
        private navCtrl: NavController,
    ) {}

    ngOnInit(): void {
        this.slides = [];
        for (let i = 1; i <= 4; ++i) {
            this.slides.push({
                index: i,
                image: `tuto${i}.png`,
                title: `tutorial.slides.slide${i}.title`,
                text: `tutorial.slides.slide${i}.text`,
            });
        }

        this.analytics.logEvent('tutorial_begin');
    }

    ionViewWillEnter(): void {
        this.slider.update();
    }

    next(): void {
        this.slider.slideNext();
    }

    getStarted(): void {
        if (this.unregisteredMode) {
            this.navCtrl.navigateForward(['/registration/after-tutorial']);
        }
        this.close();
    }

    // async getStarted(): Promise<void> {
    //     try {
    //         await this.storage.set('TUTORIAL_SEEN', 'true');

    //         this.modalService.dismissIonModal();
    //     } catch (error) {
    //         await this.toast.present({
    //             message: this.translate.instant('common.errors.default'),
    //         });
    //     }
    // }

    // async skip(): Promise<void> {
    //     try {
    //         await this.storage.set('TUTORIAL_SEEN', 'true');

    //         this.modalService.dismissIonModal();
    //     } catch (error) {
    //         await this.toast.present({
    //             message: this.translate.instant('common.errors.default'),
    //         });
    //     }
    // }

    close(): void {
        this.modalService.dismissIonModal();
    }

    async slideWillChange(): Promise<void> {
        this.activeIndex = await this.slider.getActiveIndex();

        if (this.activeIndex === this.slides.length - 1) {
            this.analytics.logEvent('tutorial_complete');
        }
    }
}
