import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { Observable } from 'rxjs';
import { startWith, switchMap, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/services/firebase/auth/auth.service';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
    constructor(private authService: AuthService) {}

    // tslint:disable-next-line: no-any
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let version = environment.iosVersion;
        if (Capacitor.getPlatform() === 'android') {
            version = environment.androidVersion;
        }

        return this.authService.getIdToken().pipe(
            take(1),
            switchMap((token) => {
                if (token) {
                    req = req.clone({
                        setHeaders: {
                            Authorization: 'Bearer ' + token,
                            'X-App-Version': version,
                        },
                    });
                }

                return next.handle(req);
            }),
        );
    }
}
