import { createReducer, on } from '@ngrx/store';
import { GameSurveyActions } from 'src/stores/game-survey/game-survey.actions';
import { GameSurveyState } from 'src/stores/game-survey/game-survey.state';

export const initialState: GameSurveyState = {
    scheduledOn: null,
    amount: null,
    restaurant: null,
    restaurants: null,
    lastLocation: null,
    currentStep: 1,
    partnersHintSkipped: false,
    game: null,
};

export const gameSurveyReducer = createReducer(
    initialState,
    on(GameSurveyActions.setStep1, (state, { scheduledOn }) => ({
        ...state,
        scheduledOn,
        currentStep: 2,
    })),
    on(GameSurveyActions.setStep2, (state, { amount }) => ({
        ...state,
        amount,
        currentStep: 3,
    })),
    on(GameSurveyActions.setStep3, (state, { restaurant }) => ({
        ...state,
        restaurant,
        currentStep: 4,
    })),
    on(GameSurveyActions.setRestaurants, (state, { items, nextPageToken, page, lastLocation }) => ({
        ...state,
        restaurants: { items, nextPageToken, page },
        lastLocation,
    })),
    on(GameSurveyActions.skipPartnersHint, (state) => ({ ...state, partnersHintSkipped: true })),
    on(GameSurveyActions.setGame, (state, { game }) => ({ ...state, game })),
    on(GameSurveyActions.reset, ({ restaurants, lastLocation, partnersHintSkipped }) => ({
        ...initialState,
        restaurants,
        lastLocation,
        partnersHintSkipped,
    })),
);
