import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { LocalStorageService } from '../../local-storage/index.service';
import { DeviceDto, DeviceResponseDto, DeviceType } from './index.dtos';

@Injectable()
export class DevicesService {
    baseUrl = environment.server.baseUri + '/device';

    constructor(private http: HttpClient, private storage: LocalStorageService) {}

    /**
     * Find device
     */
    async find(type: string, uuid: string, notificationToken: string): Promise<DeviceResponseDto> {
        return this.http
            .get<DeviceResponseDto>(`${this.baseUrl}/${type}/${uuid}/${notificationToken}`)
            .toPromise();
    }

    /**
     * Create new device
     */
    async create(dto: DeviceDto): Promise<DeviceResponseDto> {
        return this.http.post<DeviceResponseDto>(this.baseUrl, dto).toPromise();
    }

    /**
     * Remove device
     */
    async remove(type: DeviceType, uuid: string): Promise<void> {
        return this.http.delete<void>(`${this.baseUrl}/${type}/${uuid}`).toPromise();
    }
}
