import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Plugins, Capacitor, PermissionType } from '@capacitor/core';
import { DeviceType } from 'src/services/lopp-api/device/index.dtos';
import { DevicesService } from 'src/services/lopp-api/device/index.service';
import { UsersService } from 'src/services/lopp-api/user/user.service';
import { TranslateService } from '@ngx-translate/core';
import { ModalService } from 'src/services/modal.service';
const { PushNotifications, Device, Permissions } = Plugins;

@Injectable()
export class PushNotificationService {
    constructor(
        private platform: Platform,
        private usersService: UsersService,
        private deviceService: DevicesService,
        private translate: TranslateService,
        private modalService: ModalService,
    ) {}

    /**
     * Set user notification enabled and register (ask for permission if needed on iOS)
     */
    async enable(): Promise<void> {
        if (this.platform.is('capacitor')) {
            const { state } = await Permissions.query({ name: PermissionType.Notifications });
            let allowed = state === 'granted';
            if (state === 'prompt') {
                allowed = await this._showNotificationsModal();
            }
            if (allowed) {
                const { granted } = await PushNotifications.requestPermission();
                if (granted) {
                    await this.usersService.enableNotification(true);
                    await PushNotifications.register();
                }
            }
        }
    }

    async unregister(): Promise<void> {
        if (this.platform.is('capacitor')) {
            try {
                const deviceType = Capacitor.getPlatform();
                const type = deviceType.toUpperCase() as DeviceType;
                const { uuid } = await Device.getInfo();
                await this.deviceService.remove(type, uuid);
            } catch (e) {
                // silent fail
            }
        }
    }

    private async _showNotificationsModal(): Promise<boolean> {
        const options = {
            title: this.translate.instant('notification_modal.title'),
            text: this.translate.instant('notification_modal.text'),
            confirmTxt: this.translate.instant('notification_modal.submit'),
            closeIcon: true,
        };

        const response = await this.modalService.alert(options);
        return response === 'CONFIRMED';
    }
}
