import { Component, Input, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as $ from 'jquery';
import { Subject } from 'rxjs';
import { PhoneNumberVerificationComponent } from 'src/app/shared/components/phone-number-verification/phone-number-verification.component';
import { AuthService } from 'src/services/firebase/auth/auth.service';
import { LoaderService } from 'src/services/loader.service';
import { UsersService } from 'src/services/lopp-api/user/user.service';
import { ModalService } from 'src/services/modal.service';
import { ToastService } from 'src/services/toast/index.service';
import { AuthSetProfile } from 'src/stores/auth/index.actions';
import { AuthState } from 'src/stores/auth/index.state';

@Component({
    templateUrl: './set-phone-number-modal.component.html',
})
export class SetPhoneNumberModalComponent {
    @ViewChild('phoneNumberVerification')
    phoneNumberVerificationComponent: PhoneNumberVerificationComponent;

    @Input() withClose = false;
    @Input() phoneNumber: string;

    isLoading = false;

    onDestroy$ = new Subject<void>();

    constructor(
        private loader: LoaderService,
        private store: Store<{ auth: AuthState }>,
        private toast: ToastService,
        private translate: TranslateService,
        private userService: UsersService,
        private authService: AuthService,
        private modalService: ModalService,
    ) {}

    submitValidationCode(event: { otpRequestId: string; validationCode: number }): void {
        this.userService.setPhoneNumber(event.otpRequestId, event.validationCode).subscribe(
            (user) => {
                const {
                    firstName,
                    lastName,
                    lopps,
                    totalEarnings,
                    totalPendingEarnings,
                    avatar,
                    isAmbassador,
                } = user;

                this.store.dispatch(
                    new AuthSetProfile({
                        lopps,
                        totalEarnings,
                        totalPendingEarnings,
                        firstName,
                        lastName,
                        avatar,
                        isAmbassador,
                    }),
                );

                const phoneNumber =
                    this.phoneNumberVerificationComponent.phoneNumberForm.get('phoneNumber').value;

                this.close(phoneNumber);
            },
            async (error) => {
                if (error.error?.error === 'INVALID_OTP') {
                    this.toast.present({
                        message: this.translate.instant(
                            'common.phone_number_verification.invalid_code',
                        ),
                    });
                    this.loader.dismiss();
                    this.phoneNumberVerificationComponent.validationCodeForm.reset();
                    setTimeout(() => {
                        $(this.phoneNumberVerificationComponent.firstDigit.nativeElement).trigger(
                            'focus',
                        );
                    }, 400);
                } else {
                    this.close();
                    this.toast.present({
                        message: this.translate.instant('common.errors.send_data'),
                        error,
                    });
                }
            },
        );
    }

    close(phoneNumber: string = null): void {
        this.phoneNumberVerificationComponent.dismissLoader();
        this.modalService.dismissIonModal(phoneNumber);
    }

    logout(): void {
        this.authService.logout();
        this.close();
    }
}
