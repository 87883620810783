import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { LayoutModule } from './layout/layout.module';
import { SharedModule } from './shared/shared.module';
import { LoginModule } from './login/login.module';
import { TutorialModule } from './tutorial/tutorial.module';
import { AuthService } from '../services/firebase/auth/auth.service';
import { UsersService } from '../services/lopp-api/user/user.service';
import { LocalStorageService } from '../services/local-storage/index.service';
import { StoreModule } from '@ngrx/store';
import { rootEffects, rootReducer } from '../stores/root.reducer';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { PackagesService } from '../services/lopp-api/package/index.service';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ModalService } from 'src/services/modal.service';
import { AgmCoreModule } from '@agm/core';
import { GameService } from './game/game.service';
import { GooglePlacesService } from '../services/google-places/index.service';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { environment } from 'src/environments/environment';
import { ToastService } from '../services/toast/index.service';
import { GeolocationService } from '../services/geolocation';
import { GamesService } from '../services/lopp-api/game/index.service';
import { PaymentsService } from '../services/lopp-api/payment/index.service';
import { RemoteConfigService } from '../services/firebase/remote-config/index.service';
import { AngularFireRemoteConfigModule, SETTINGS } from '@angular/fire/remote-config';
import { DevicesService } from '../services/lopp-api/device/index.service';
import { PushNotificationService } from '../services/push-notification/index.service';
import { RestaurantService } from 'src/services/lopp-api/restaurant/index.service';
import { storageSyncMetaReducer } from 'ngrx-store-persist';
import { OpenNativeSettings } from '@ionic-native/open-native-settings/ngx';
import { HttpResponseInterceptor } from 'src/app/http-response.interceptors';
import { InAppPurchase2 } from '@ionic-native/in-app-purchase-2/ngx';
import { InAppPurchaseService } from 'src/services/in-app-purchase.service';
import { LoaderService } from 'src/services/loader.service';
import { HttpRequestInterceptor } from 'src/app/http-request.interceptor';
import { EmailComposer } from '@ionic-native/email-composer/ngx';
import { AnalyticsService } from 'src/services/firebase/analytics/index.service';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
import { NetworkService } from 'src/services/network.service';
import { SignupService } from 'src/services/signup.service';
import { FacebookLoginService } from 'src/services/facebook-login.service';
import { GoogleLoginService } from 'src/services/google-login.service';
import { AppleLoginService } from 'src/services/apple-login.service';
import { LogService } from 'src/services/lopp-api/log/log.service';
import { HapticService } from 'src/services/haptic.service';
import { EffectsModule } from '@ngrx/effects';

import * as dayjs from 'dayjs';
import * as relativeTime from 'dayjs/plugin/relativeTime';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { DeviceService } from 'src/services/device.service';
dayjs.extend(relativeTime);

// required for AOT compilation
export function httpLoaderFactory(http: HttpClient): TranslateHttpLoader {
    return new TranslateHttpLoader(http);
}

@NgModule({
    imports: [
        SharedModule,
        BrowserModule,
        HttpClientModule,
        IonicModule
            .forRoot
            // { _forceStatusbarPadding: true }
            (),
        AgmCoreModule.forRoot({
            apiKey: environment.googleMapsApiKey,
            libraries: ['places', 'geocoder', 'visualization', 'geometry'],
        }),

        /**
         * App modules
         */
        AppRoutingModule,
        LayoutModule,
        LoginModule,
        TutorialModule,

        /**
         * Plugins modules
         */
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireAnalyticsModule,
        AngularFireAuthModule,
        AngularFireRemoteConfigModule,
        StoreModule.forRoot(rootReducer, { metaReducers: [storageSyncMetaReducer] }),
        StoreDevtoolsModule.instrument({
            maxAge: 25, // Retains last 25 states
            logOnly: environment.production, // Restrict extension to log-only mode
        }),
        EffectsModule.forRoot(rootEffects),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: httpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        AngularSvgIconModule.forRoot(),
    ],
    declarations: [AppComponent],
    entryComponents: [],
    providers: [
        StatusBar,
        SplashScreen,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },

        /**
         * Plugins
         */
        // Configs for FRC
        {
            provide: SETTINGS,
            useFactory: () =>
                environment.production
                    ? { minimumFetchIntervalMillis: 300000 }
                    : { minimumFetchIntervalMillis: 30000 },
        },
        OpenNativeSettings,
        InAppPurchase2,
        EmailComposer,

        /**
         * Interceptors
         */
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpResponseInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpRequestInterceptor,
            multi: true,
        },

        /**
         * Services
         */
        LocalStorageService,
        AuthService,
        GooglePlacesService,
        UsersService,
        PackagesService,
        GamesService,
        PaymentsService,
        RemoteConfigService,
        DevicesService,
        PushNotificationService,
        RestaurantService,
        InAppPurchaseService,
        NetworkService,
        LoaderService,
        AnalyticsService,
        SignupService,
        FacebookLoginService,
        GoogleLoginService,
        AppleLoginService,
        LogService,
        HapticService,
        DeviceService,

        /**
         * Components
         */
        ModalService,
        GameService,
        ToastService,
        GeolocationService,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
