import { Injectable } from '@angular/core';
import { Utils } from '../../app/shared/utils';

import { PermissionType, Plugins } from '@capacitor/core';
import { Store } from '@ngrx/store';
import { LocationActions } from 'src/stores/location/index.actions';
const { Geolocation, Permissions } = Plugins;
import { OpenNativeSettings } from '@ionic-native/open-native-settings/ngx';
import { ToastService } from 'src/services/toast/index.service';
import { Platform } from '@ionic/angular';

export interface Location {
    lat: number;
    lng: number;
}

@Injectable()
export class GeolocationService {
    constructor(
        private store: Store,
        private openNativeSettings: OpenNativeSettings,
        private toastService: ToastService,
        private platform: Platform,
    ) {}

    async update(openSettingsIfDenied = false): Promise<void> {
        try {
            const { state } = await Permissions.query({ name: PermissionType.Geolocation });
            if (this.platform.is('ios') && this.platform.is('capacitor') && state === 'denied') {
                if (openSettingsIfDenied) {
                    this.openNativeSettings.open('application_details');
                } else {
                    this.store.dispatch(LocationActions.disable());
                }

                return;
            }

            const {
                coords: { latitude: lat, longitude: lng },
            } = await Geolocation.getCurrentPosition({ enableHighAccuracy: false, timeout: 10000 });

            this.store.dispatch(LocationActions.enable());
            this.store.dispatch(LocationActions.update({ coords: { lat, lng } }));
        } catch (e) {
            console.log('geolocation error:', e);
        }
    }

    debugMode(): void {
        void this.toastService.present({
            message: 'Debug mode enabled, location is in Paris...',
        });
        this.store.dispatch(LocationActions.debugMode());
        this.store.dispatch(LocationActions.update({ coords: Utils.fixedLocation() }));
    }
}
