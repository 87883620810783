import { Injectable } from '@angular/core';
import { AngularFireRemoteConfig } from '@angular/fire/remote-config';

export class RemoteConfig {
    MIN_BILL_AMOUNT: number;
    MAX_BILL_AMOUNT: number;
    SIGNUP_LOPPS: number;
    DAILY_GAMES_LIMIT: number;
    DAILY_RESTAURANT_GAMES_LIMIT: number;
    SUPPORT_EMAIL: string;
    PRIVACY_POLICY_URL_FR: string;
    PRIVACY_POLICY_URL_EN: string;
    GENERAL_CONDITIONS_URL_FR: string;
    GENERAL_CONDITIONS_URL_EN: string;
    IOS_APP_VERSION: string;
    ANDROID_APP_VERSION: string;
    APPSTORE_URL: string;
    PLAYSTORE_URL: string;
    APP_SHARE_URL: string;
    COMMUNITY_EARNINGS_DAY_RANGE: number;
    MIN_CASHOUT_AMOUNT: number;
    REFERRAL_LOPPS: number;
    GAME_LOPP_LOPPS: number;
    GAME_SUPERLOPP_LOPPS: number;
    GAME_PROTECT_LOPPS: number;
    BUY_ONE_GET_ONE: number;
    SHOW_COINS_VIDEO_ANIMATION: number;
}

export type RemoteConfigKeyString =
    | 'SUPPORT_EMAIL'
    | 'PRIVACY_POLICY_URL_FR'
    | 'PRIVACY_POLICY_URL_EN'
    | 'GENERAL_CONDITIONS_URL_FR'
    | 'GENERAL_CONDITIONS_URL_EN'
    | 'IOS_APP_VERSION'
    | 'ANDROID_APP_VERSION'
    | 'APPSTORE_URL'
    | 'PLAYSTORE_URL'
    | 'APP_SHARE_URL';

export type RemoteConfigKeyNumber =
    | 'MIN_BILL_AMOUNT'
    | 'MAX_BILL_AMOUNT'
    | 'SIGNUP_LOPPS'
    | 'DAILY_GAMES_LIMIT'
    | 'DAILY_RESTAURANT_GAMES_LIMIT'
    | 'COMMUNITY_EARNINGS_DAY_RANGE'
    | 'MIN_CASHOUT_AMOUNT'
    | 'REFERRAL_LOPPS'
    | 'GAME_LOPP_LOPPS'
    | 'GAME_SUPERLOPP_LOPPS'
    | 'GAME_PROTECT_LOPPS'
    | 'BUY_ONE_GET_ONE'
    | 'SHOW_COINS_VIDEO_ANIMATION';

@Injectable()
export class RemoteConfigService {
    constructor(private ngFireRemoteConfig: AngularFireRemoteConfig) {}

    async getString(key: RemoteConfigKeyString): Promise<string> {
        await this._fetchAndActivate();

        return this.ngFireRemoteConfig.getString(key);
    }

    async getNumber(key: RemoteConfigKeyNumber): Promise<number> {
        await this._fetchAndActivate();

        return this.ngFireRemoteConfig.getNumber(key);
    }

    private async _fetchAndActivate(): Promise<void> {
        await this.ngFireRemoteConfig.fetchAndActivate();
    }
}
