import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    forwardRef,
    Host,
    Input,
    OnDestroy,
    OnInit,
    Optional,
    Output,
    SkipSelf,
    ViewChild,
} from '@angular/core';
import {
    AbstractControl,
    ControlContainer,
    ControlValueAccessor,
    FormBuilder,
    FormControl,
    NG_VALUE_ACCESSOR,
    Validators,
} from '@angular/forms';
import * as $ from 'jquery';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export interface ValidationMessages {
    [key: string]: string | ((control: AbstractControl) => string);
}

@Component({
    selector: 'app-forms-validation-code',
    templateUrl: './validation-code.component.html',
    styleUrls: ['./validation-code.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ValidationCodeComponent),
            multi: true,
        },
    ],
})
export class ValidationCodeComponent
    implements OnInit, AfterViewInit, OnDestroy, ControlValueAccessor
{
    @ViewChild('firstDigit') firstDigitElement: ElementRef;

    @Input() color: 'white' | 'gray' = 'gray';
    @Input() label?: string;
    @Input() formControlName: string;
    @Input() placeholder?: string;
    @Input() hint?: string;
    @Input() icon?: string;
    @Input() type: 'text' | 'password' | 'email' | 'number' = 'text';
    @Input() validationMessages: ValidationMessages;
    @Input() inputMode: 'text' | 'numeric' | 'tel' | 'email' = 'text';
    @Input() autofocus = false;
    @Input() center = false;

    @Output() onCode = new EventEmitter<number>();

    control: FormControl;
    passwordVisible = false;
    forceType: string;

    propagateChange: (_: number) => void;
    propagateTouch: () => void;

    onDestroy$ = new Subject<void>();

    form = this.fb.group({
        digit1: ['', Validators.required],
        digit2: ['', Validators.required],
        digit3: ['', Validators.required],
        digit4: ['', Validators.required],
    });

    constructor(
        @Optional() @Host() @SkipSelf() private controlContainer: ControlContainer,
        private cdr: ChangeDetectorRef,
        private fb: FormBuilder,
    ) {}

    ngOnInit(): void {
        if (this.controlContainer) {
            this.control = this.controlContainer.control.get(this.formControlName) as FormControl;
        }
    }

    ngAfterViewInit(): void {
        if (this.autofocus) {
            setTimeout(() => {
                this.focus();
            }, 400);
        }
        this.form.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe((values) => {
            const value = values.digit1 + values.digit2 + values.digit3 + values.digit4;

            if (value.length === 4) {
                this.control.setValue(+value);
                this.onCode.emit(+value);
            } else {
                this.control.setValue(null);
            }
        });

        $(document).on('keyup', '.ValidationCode-item input', (e) => {
            const id = e.target.id;
            const elem = $('#' + id);

            const key = +e.key;
            // if (key >= 0 && key <= 9) {
            if (e.key !== 'Backspace') {
                if (id === 'digit4') {
                    elem.trigger('blur');
                    // this.submitValidationCode();
                } else {
                    const index = +id.slice(5);
                    $('input#digit' + (index + 1)).trigger('focus');
                }
            }
            if (e.key === 'Backspace' && !elem.val()) {
                if (id !== 'digit1') {
                    const index = +id.slice(5);
                    this.form.get('digit' + (index - 1)).patchValue(null);
                    $('input#digit' + (index - 1)).trigger('focus');
                }
            }
        });
    }

    focus(): void {
        $(this.firstDigitElement.nativeElement).trigger('focus');
    }

    registerOnChange(fn: (_: number) => void): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.propagateTouch = fn;
    }

    writeValue(value: number): void {
        if (value) {
            this.propagateChange(value);
            this.propagateTouch();
        }
    }

    reset(): void {
        this.propagateChange(null);
        this.form.get('digit1').setValue(null);
        this.form.get('digit2').setValue(null);
        this.form.get('digit3').setValue(null);
        this.form.get('digit4').setValue(null);
    }

    setDisabledState(isDisabled: boolean): void {
        isDisabled ? this.control?.disable() : this.control?.enable();
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }
}
