import { createAction, props } from '@ngrx/store';
import { Location } from 'src/services/geolocation';
import { GetPartnersDto, RestaurantResponseDto } from 'src/services/lopp-api/restaurant/index.dtos';

const domain = '[RESTAURANTS]';

const search = createAction(`${domain} Search`, props<{ searchQuery: string }>());
const centerMap = createAction(`${domain} Center map`, props<{ mapCenter: Location }>());

const loadPartners = createAction(`${domain} Load Partners`);
const loadPartnersSuccess = createAction(
    `${domain} Load Partners Success`,
    props<{ partners: RestaurantResponseDto[] }>(),
);

const setNonPartners = createAction(
    `${domain} Set non partners`,
    props<{ items: RestaurantResponseDto[]; page: number; nextPageToken: string }>(),
);

export const RestaurantsActions = {
    search,
    centerMap,
    loadPartners,
    loadPartnersSuccess,
    setNonPartners,
};
