import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { LocalStorageService } from '../../local-storage/index.service';
import { Observable } from 'rxjs';
import {
    GetPartnersDto,
    RestaurantDetailResponseDto,
    RestaurantResponseDto,
    SearchDto,
    SearchResultsResponseDto,
} from 'src/services/lopp-api/restaurant/index.dtos';
import { classToPlain, plainToClass } from 'class-transformer';
import { map } from 'rxjs/operators';

enum Endpoint {
    GetPartners = '/partners',
    GetDetail = '/detail',
    Search = '/search',
}

@Injectable()
export class RestaurantService {
    baseUrl = environment.server.baseUri + '/restaurant';

    constructor(private http: HttpClient, private storage: LocalStorageService) {}

    getPartners(dto: GetPartnersDto): Observable<RestaurantResponseDto[]> {
        const endpoint = `${this.baseUrl}${Endpoint.GetPartners}`;
        const params = new HttpParams({ fromObject: classToPlain(dto) });

        return this.http.get<RestaurantResponseDto[]>(endpoint, { params }).pipe(
            map((data) => {
                const { lat, lng } = dto;

                return data.map((item) => {
                    item = plainToClass(RestaurantResponseDto, item);
                    item.distance = item.getDistance({ lat, lng });

                    return item;
                });
            }),
        );
    }

    getDetail(googlePlaceId: string): Observable<RestaurantDetailResponseDto> {
        const endpoint = `${this.baseUrl}${Endpoint.GetDetail}/${googlePlaceId}`;

        return this.http
            .get<RestaurantDetailResponseDto>(endpoint)
            .pipe(map((data) => plainToClass(RestaurantDetailResponseDto, data)));
    }

    search(dto: SearchDto): Observable<SearchResultsResponseDto> {
        const endpoint = `${this.baseUrl}${Endpoint.Search}`;
        const params = new HttpParams({ fromObject: classToPlain(dto) });

        return this.http.get<SearchResultsResponseDto>(endpoint, { params }).pipe(
            map((data) => {
                const { lat, lng } = dto;

                data = plainToClass(SearchResultsResponseDto, data);
                data.items = data.items.map((item) => {
                    item.distance = item.getDistance({ lat, lng });

                    return item;
                });

                return data;
            }),
        );
    }
}
