import { Injectable } from '@angular/core';
import { UsersService } from 'src/services/lopp-api/user/user.service';
import { Store } from '@ngrx/store';
import { AuthService } from 'src/services/firebase/auth/auth.service';
import { AuthState } from 'src/stores/auth/index.state';
import {
    SignInWithAppleResponse,
    SignInWithAppleOptions,
} from '@capacitor-community/apple-sign-in';
import { Plugins } from '@capacitor/core';
import firebase from 'firebase/app';
import { environment } from 'src/environments/environment';
const { SignInWithApple } = Plugins;
import { NavController } from '@ionic/angular';
import { RegistrationActions } from 'src/stores/registration/registration.actions';
import { SignupService } from 'src/services/signup.service';

@Injectable()
export class AppleLoginService {
    constructor(
        private usersService: UsersService,
        private store: Store<{ auth: AuthState }>,
        private authService: AuthService,
        private navCtrl: NavController,
        private signupService: SignupService,
    ) {}

    async login(): Promise<boolean> {
        const options: SignInWithAppleOptions = {
            clientId: 'fr.lopp.lopp',
            redirectURI: `https://${environment.firebase.projectId}.firebaseapp.com/__/auth/handler`,
            scopes: 'email name',
        };

        let response: SignInWithAppleResponse;
        try {
            response = await SignInWithApple.authorize(options);
        } catch (e) {
            return false;
        }

        const {
            email,
            familyName: lastName,
            givenName: firstName,
            identityToken,
        } = response.response;

        if (email && !(await this.signupService.checkEmail(email, 'apple.com'))) {
            return false;
        }

        const provider = new firebase.auth.OAuthProvider('apple.com');
        const credential = provider.credential({ idToken: identityToken });

        const { user } = await this.authService.instance().signInWithCredential(credential);

        const idToken = await user.getIdToken();
        const hasAccount = await this.usersService.hasAccount(idToken);
        if (hasAccount) {
            this.navCtrl.navigateRoot('/home', { animationDirection: 'forward' });

            return true;
        }

        this.store.dispatch(
            RegistrationActions.appleRegistration({ email, firstName, lastName, idToken }),
        );
        this.navCtrl.navigateForward(['/registration/register']);

        return false;
    }
}
