import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { PhoneNumber } from 'google-libphonenumber';
import * as $ from 'jquery';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LoaderService } from 'src/services/loader.service';
import { API_ERRORS } from 'src/services/lopp-api';
import { Language } from 'src/services/lopp-api/user/user.dtos';
import { UsersService } from 'src/services/lopp-api/user/user.service';
import { ModalService } from 'src/services/modal.service';
import { ToastService } from 'src/services/toast/index.service';
import { AuthState } from 'src/stores/auth/index.state';

@Component({
    selector: 'app-shared-phone-number-verification',
    templateUrl: './phone-number-verification.component.html',
    styleUrls: ['./phone-number-verification.component.scss'],
})
export class PhoneNumberVerificationComponent implements OnInit, OnDestroy {
    @Input() phoneNumber: PhoneNumber;
    @Output() onSubmit = new EventEmitter<{ otpRequestId: string; validationCode: number }>();

    @ViewChild('firstDigit') firstDigit: ElementRef;

    isLoading = false;

    showPhoneNumberForm = true;
    showValidationCodeForm = false;

    otpRequestId: string;

    phoneNumberForm: FormGroup = this.fb.group({
        phoneNumber: ['', Validators.required],
    });

    validationCodeForm: FormGroup = this.fb.group({
        digit1: ['', Validators.required],
        digit2: ['', Validators.required],
        digit3: ['', Validators.required],
        digit4: ['', Validators.required],
    });

    language: Language;

    onDestroy$ = new Subject<void>();

    constructor(
        private fb: FormBuilder,
        private loader: LoaderService,
        private modalService: ModalService,
        private usersService: UsersService,
        private toast: ToastService,
        private translate: TranslateService,
        private store: Store<{ auth: AuthState }>,
    ) {}

    ngOnInit(): void {
        this.store.pipe(select('auth'), takeUntil(this.onDestroy$)).subscribe(({ language }) => {
            this.language = language;
        });

        if (this.phoneNumber) {
            this.phoneNumberForm.patchValue({ phoneNumber: this.phoneNumber });
        }

        this.validationCodeForm.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
            const keys = ['digit1', 'digit2', 'digit3', 'digit4'];
            for (const key of keys) {
                const control = this.validationCodeForm.get(key);
                const value = control.value;
                console.log('value', value);
                if (value && value.length > 1 && value.length < 4) {
                    control.patchValue('');
                }
                if (value && value.length === 4) {
                    this.validationCodeForm.setValue(
                        {
                            digit1: value[0],
                            digit2: value[1],
                            digit3: value[2],
                            digit4: value[3],
                        },
                        { emitEvent: false },
                    );
                    this.submitValidationCode();
                }
            }
        });

        $(document).on('keyup', '.PhoneNumberVerificationModal-code-number input', (e) => {
            const id = e.target.id;
            const elem = $('#' + id);

            console.log('keyup', e, id);

            const key = +e.key;
            // if (key >= 0 && key <= 9) {
            if (e.key !== 'Backspace') {
                if (id === 'digit4') {
                    elem.trigger('blur');
                    this.submitValidationCode();
                } else {
                    const index = +id.slice(5);
                    $('input#digit' + (index + 1)).trigger('focus');
                }
            }
            if (e.key === 'Backspace' && !elem.val()) {
                if (id !== 'digit1') {
                    console.log('remove');
                    const index = +id.slice(5);
                    this.validationCodeForm.get('digit' + (index - 1)).patchValue(null);
                    $('input#digit' + (index - 1)).trigger('focus');
                }
            }
        });
    }

    async submitPhoneNumberForm(): Promise<void> {
        this.loader.present();
        const phoneNumber = this.phoneNumberForm.get('phoneNumber').value;
        this.usersService.requestOTP(phoneNumber, this.language).subscribe(
            ({ id }) => {
                this.loader.dismiss();
                this.showPhoneNumberForm = false;
                this.showValidationCodeForm = true;
                this.otpRequestId = id;
                setTimeout(() => {
                    $(this.firstDigit.nativeElement).trigger('focus');
                }, 400);
            },
            async (error) => {
                this.loader.dismiss();
                const message = API_ERRORS[error.error?.error];
                this.toast.present({
                    message: this.translate.instant(message || 'common.errors.default'),
                });
            },
        );
    }

    submitValidationCode(): void {
        this.loader.present();
        const validationCode = this._getValidationCode();
        this.onSubmit.emit({ otpRequestId: this.otpRequestId, validationCode });
    }

    resend(): void {
        this.showValidationCodeForm = false;
        this.validationCodeForm.reset();
        this.showPhoneNumberForm = true;
    }

    private _getValidationCode(): number {
        const digit1 = this.validationCodeForm.get('digit1').value;
        const digit2 = this.validationCodeForm.get('digit2').value;
        const digit3 = this.validationCodeForm.get('digit3').value;
        const digit4 = this.validationCodeForm.get('digit4').value;

        return +(digit1 + digit2 + digit3 + digit4);
    }

    dismissLoader(): void {
        this.loader.dismiss();
    }

    ngOnDestroy(): void {
        $(document).off('keyup', '.PhoneNumberVerificationModal-code-number input');

        this.onDestroy$.next();
        this.onDestroy$.complete();
    }
}
