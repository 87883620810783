import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { LOGIN_COMPONENTS } from './components';
import { LOGIN_MODALS } from './modals';

@NgModule({
    imports: [
        SharedModule,
    ],
    declarations: [
        ...LOGIN_MODALS,
        ...LOGIN_COMPONENTS,
    ],
    exports: [
        ...LOGIN_MODALS,
    ],
})
export class LoginModule { }
