import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Capacitor } from '@capacitor/core';

@Component({
    selector: 'app-shared-page-title',
    templateUrl: './page-title.component.html',
    styleUrls: ['./page-title.component.scss'],
})
export class PageTitleComponent {
    @Input() title: string;
    @Input() subtitle?: string;
    @Input() buyLopps = false;
    @Input() amount?: string;
    @Input() noPaddingTop = false;
    @Input() smallPaddingBottom = false;

    @Output() onBuyLopps: EventEmitter<void> = new EventEmitter<void>();

    platform = Capacitor.platform;
}
