import { Location } from 'src/services/geolocation';

export class Utils {
    /**
     * Wait with delay (ms)
     */
    static async wait(delay: number): Promise<void> {
        return new Promise((resolve) => {
            setTimeout(resolve, delay);
        });
    }

    /**
     * Default fixed location (Paris)
     */
    static fixedLocation(): Location {
        return {
            lat: 48.8534,
            lng: 2.3488,
        };
    }

    /**
     * Convert dataURI to Blob
     */
    static dataURItoBlob(dataURI: string): Blob {
        const byteString = atob(dataURI.split(',')[1]);
        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);

        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        const blob = new Blob([ab], { type: mimeString });

        return blob;
    }

    /**
     * Capitalize word
     */
    static capitalize(str: string): string {
        const lower = str.toLowerCase();

        return str.charAt(0).toUpperCase() + lower.slice(1);
    }

    /**
     * Get distance between a and b in metters
     */
    static getDistance(a: Location, b: Location): number {
        if (!a || !b) {
            return null;
        }

        const degreesToRadians = (degrees: number) => {
            return (degrees * Math.PI) / 180;
        };

        const earthRadiusKm = 6371;

        const dLat = degreesToRadians(a.lat - b.lat);
        const dLon = degreesToRadians(a.lng - b.lng);

        const arc =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.sin(dLon / 2) *
                Math.sin(dLon / 2) *
                Math.cos(degreesToRadians(b.lat)) *
                Math.cos(degreesToRadians(a.lat));

        const c = 2 * Math.atan2(Math.sqrt(arc), Math.sqrt(1 - arc));

        const result = Math.round((earthRadiusKm * c + Number.EPSILON) * 100) / 100;

        return result * 1000;
    }
}
