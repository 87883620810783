import { ChangeDetectorRef, Component, ResolvedReflectiveFactory } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-shared-splash-screen',
    templateUrl: './splash-screen.component.html',
    styleUrls: ['./splash-screen.component.scss'],
})
export class SplashScreenComponent {
    splashScreenExists = true;
    splashScreenVisible = environment.showSplashScreen;

    constructor(private cdr: ChangeDetectorRef) {}

    async hideSplashScreen(): Promise<void> {
        return new Promise((resolve) => {
            setTimeout(() => {
                this.splashScreenVisible = false;
                this.cdr.detectChanges();

                setTimeout(() => {
                    this.splashScreenExists = false;
                    this.cdr.detectChanges();
                    resolve();
                }, 200);
            }, 1500);
        });
    }
}
