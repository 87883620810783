import {
    AfterViewInit,
    Component,
    EventEmitter,
    forwardRef,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    FormControl,
    FormGroup,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
} from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
    SelectModalComponent,
    SelectOption,
} from 'src/app/shared/components/select-modal/select-modal.component';
import { ModalService } from 'src/services/modal.service';
import * as libPhoneNumber from 'google-libphonenumber';
import * as countryTelData from 'country-telephone-data';

@Component({
    selector: 'app-shared-phone-number-input',
    templateUrl: './phone-number-input.component.html',
    styleUrls: ['./phone-number-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => PhoneNumberInputComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: PhoneNumberInputComponent,
        },
    ],
})
export class PhoneNumberInputComponent
    implements OnInit, AfterViewInit, OnDestroy, ControlValueAccessor, Validator
{
    @Input() form: FormGroup;
    @Input() formControlName: string;
    @Input() readonly = false;

    @Output() touched = new EventEmitter<void>();

    control = new FormControl();
    countryCode: string;
    phoneNumber: libPhoneNumber.PhoneNumber;
    placeholder: string;
    errorMessages: string[] = [];
    countryCodeChanged = false;

    onDestroy$ = new Subject<void>();

    phoneNumberUtil = libPhoneNumber.PhoneNumberUtil.getInstance();

    propagateChange: (_: string) => void;
    propagateTouch: () => void;

    constructor(private modalService: ModalService) {}

    ngOnInit(): void {
        this._setCountryCode('33');
    }

    ngAfterViewInit(): void {
        this.control?.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe((value) => {
            if (!value) {
                this.propagateChange(null);

                return;
            }

            try {
                this.phoneNumber = this.phoneNumberUtil.parse(this.countryCode + value);
                this.propagateChange(
                    this.phoneNumberUtil.format(
                        this.phoneNumber,
                        libPhoneNumber.PhoneNumberFormat.E164,
                    ),
                );
            } catch {
                this.phoneNumber = null;
                this.propagateChange('invalid');
            }
        });

        this.touched.pipe(takeUntil(this.onDestroy$)).subscribe(() => this.propagateTouch());
    }

    registerOnChange(fn: (_: string) => void): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.propagateTouch = fn;
    }

    writeValue(value: string): void {
        if (value) {
            try {
                this.phoneNumber = this.phoneNumberUtil.parse(value);
                this.countryCode = '+' + this.phoneNumber.getCountryCode();
                this.control.setValue(
                    this.phoneNumberUtil.format(
                        this.phoneNumber,
                        libPhoneNumber.PhoneNumberFormat.NATIONAL,
                    ),
                );
            } catch {
                this.phoneNumber = null;
                this.control.setValue(null);
            }
        }
    }

    setDisabledState(isDisabled: boolean): void {
        isDisabled ? this.control?.disable() : this.control?.enable();
    }

    validate(control: AbstractControl): ValidationErrors | null {
        if (!control.value) {
            this.errorMessages = [];

            return null;
        }

        if (!this.phoneNumber || !this.phoneNumberUtil.isValidNumber(this.phoneNumber)) {
            const errors: ValidationErrors = {
                phoneNumber: 'invalid',
            };

            this.errorMessages = ['forms.errors.invalid_phone_number'];

            return errors;
        }

        this.errorMessages = [];

        return null;
    }

    async selectCountryCode(): Promise<void> {
        const options: SelectOption[] = [];
        for (const country of countryTelData.allCountries) {
            const { name, dialCode } = country;
            options.push({
                label: `${name} +${dialCode}`,
                value: dialCode,
            });
        }

        const instance = await this.modalService.openIonModal({
            component: SelectModalComponent,
            swipeToClose: true,
            componentProps: {
                options,
            },
        });
        const { data } = await instance.onWillDismiss<SelectOption>();
        if (data) {
            this._setCountryCode(data.value);
            this.countryCodeChanged = true;
        }
    }

    private _setCountryCode(countryCode: string) {
        this.countryCode = '+' + countryCode;
        this.placeholder = this.phoneNumberUtil.format(
            this.phoneNumberUtil.getExampleNumberForType(
                this.phoneNumberUtil.getRegionCodeForCountryCode(+countryCode),
                libPhoneNumber.PhoneNumberType.MOBILE,
            ),
            libPhoneNumber.PhoneNumberFormat.NATIONAL,
        );
        this.control.reset();
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }
}
