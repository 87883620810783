import { Component, HostBinding, Input } from '@angular/core';

@Component({
    selector: 'app-shared-basic-header',
    templateUrl: './basic-header.component.html',
    styleUrls: ['./basic-header.component.scss'],
})
export class BasicHeaderComponent {
    @HostBinding('className') componentClass = 'BasicHeader';

    @Input() color: 'blue' | 'yellow' = 'blue';

    @Input() title: string;
    @Input() subtitle?: string;

    @Input() withNavBack = false;
    @Input() withClose = false;

    @Input() backUrl: string | string[];
}
