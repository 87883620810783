import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { GameLimitReachedModalComponent } from 'src/app/shared/components/game-limit-reached-modal/game-limit-reached-modal.component';
import { RemoteConfigService } from 'src/services/firebase/remote-config/index.service';
import { API_ERRORS } from 'src/services/lopp-api';
import { GamesService as GameApiService } from 'src/services/lopp-api/game/index.service';
import { RestaurantResponseDto } from 'src/services/lopp-api/restaurant/index.dtos';
import { ModalService } from 'src/services/modal.service';
import { ToastService } from 'src/services/toast/index.service';
import { GameSurveySelectors } from 'src/stores/game-survey/game-survey.selectors';
import { AuthSelectors } from 'src/stores/auth/index.selectors';
import { NetworkService } from 'src/services/network.service';
import {
    GamePlayedResponseDto,
    GameResponseDto,
    PlayGameType,
    StartGameDto,
} from 'src/services/lopp-api/game/index.dtos';
import {
    AuthAddCredits,
    AuthAddPendingEarnings,
    AuthRemoveCredits,
} from 'src/stores/auth/index.actions';
import { AnalyticsService } from 'src/services/firebase/analytics/index.service';
import { GameSurveyActions } from 'src/stores/game-survey/game-survey.actions';
import { HttpErrorResponse } from '@angular/common/http';
import { NavController } from '@ionic/angular';

@Injectable()
export class GameService {
    constructor(
        private modalService: ModalService,
        private translate: TranslateService,
        private gameApiService: GameApiService,
        private store: Store,
        private toast: ToastService,
        private remoteConfig: RemoteConfigService,
        private networkService: NetworkService,
        private analytics: AnalyticsService,
        private navCtrl: NavController,
    ) {}

    async getActiveGame(): Promise<GameResponseDto> {
        return this.gameApiService.getActiveGame();
    }

    async startGame(restaurant: RestaurantResponseDto): Promise<GameResponseDto> {
        if (!(await this.networkService.checkNetWorkStatus())) {
            return;
        }

        if (!(await this.checkLimit(restaurant))) {
            return null;
        }

        const { scheduledOn, amount } = await this.store
            .select(GameSurveySelectors.selectState)
            .pipe(take(1))
            .toPromise();

        const dto: StartGameDto = {
            scheduledOn,
            amount,
            googlePlaceId: restaurant.googlePlaceId,
        };

        try {
            return this.gameApiService.start(dto);
        } catch (error) {
            const message = API_ERRORS[error.error?.error];
            if (message) {
                this.toast.present({
                    message: this.translate.instant(message),
                    error,
                });
            } else {
                this.toast.present({
                    message: this.translate.instant('common.errors.send_data'),
                    error,
                });
            }
            return null;
        }
    }

    async checkLimit(restaurant: RestaurantResponseDto = null): Promise<boolean> {
        const { global: globalCount, restaurant: restaurantCount } =
            await this.gameApiService.userDailyPlayed(restaurant ? restaurant.googlePlaceId : null);

        const isAmbassador = await this.store
            .select(AuthSelectors.selectIsAmbassador)
            .pipe(take(1))
            .toPromise();

        const globalLimit = (await this.remoteConfig.getNumber('DAILY_GAMES_LIMIT')) || 3;
        if (!isAmbassador && globalCount >= globalLimit) {
            this.modalService.openModal(GameLimitReachedModalComponent, { type: 'global' });
            return false;
        }

        if (restaurant) {
            const restaurantLimit =
                (await this.remoteConfig.getNumber('DAILY_RESTAURANT_GAMES_LIMIT')) || 1;
            if (!isAmbassador && restaurantCount >= restaurantLimit) {
                this.modalService.openModal(GameLimitReachedModalComponent, { type: 'restaurant' });
                return false;
            }
        }

        return true;
    }

    async play(game: GameResponseDto, type: PlayGameType): Promise<GamePlayedResponseDto> {
        if (!(await this.networkService.checkNetWorkStatus())) {
            return;
        }

        try {
            const result = await this.gameApiService.play(game.id, type).toPromise();
            const lopps = type === 'LOPP' ? 1 : 3;

            game.earning = result.earning;
            game.status = 'STARTED';
            game.canUnprotect = !game.isProtected;

            this.store.dispatch(new AuthRemoveCredits({ lopps }));
            this.store.dispatch(GameSurveyActions.setGame({ game }));

            this._logEvent(game, type === 'LOPP' ? 'lopp_clicked' : 'superlopp_clicked');

            return result;
        } catch (error) {
            if (error instanceof HttpErrorResponse && error.error.error === 'NOT_ENOUGH_LOPPS') {
                this._notEnoughLoppsModal();
            } else {
                await this.toast.present({
                    message: this.translate.instant('common.errors.send_data'),
                    error,
                });
            }

            return null;
        }
    }

    async endGame(game: GameResponseDto): Promise<boolean> {
        if (!(await this.networkService.checkNetWorkStatus())) {
            return false;
        }

        try {
            await this.gameApiService.validate(game.id);
            this.store.dispatch(new AuthAddPendingEarnings({ pendingEarnings: game.earning }));
            this._logEvent(game, 'cashback_earned');
        } catch (error) {
            await this.toast.present({
                message: this.translate.instant('common.errors.send_data'),
                error,
            });

            return false;
        }

        return true;
    }

    async protect(game: GameResponseDto): Promise<boolean> {
        if (!(await this.networkService.checkNetWorkStatus())) {
            return false;
        }

        try {
            await this.gameApiService.protect(game.id).toPromise();

            game.isProtected = true;
            game.canUnprotect = true;

            const lopps = 1;
            this.store.dispatch(new AuthRemoveCredits({ lopps }));
            this.store.dispatch(GameSurveyActions.setGame({ game }));

            this._logEvent(game, 'protect_clicked');

            return true;
        } catch (error) {
            if (error instanceof HttpErrorResponse && error.error.error === 'NOT_ENOUGH_LOPPS') {
                this._notEnoughLoppsModal();
            } else {
                await this.toast.present({
                    message: this.translate.instant('common.errors.send_data'),
                    error,
                });
            }

            return false;
        }
    }

    async unprotect(game: GameResponseDto): Promise<boolean> {
        if (!(await this.networkService.checkNetWorkStatus())) {
            return false;
        }

        try {
            await this.gameApiService.unprotect(game.id).toPromise();

            game.isProtected = false;
            game.canUnprotect = false;

            const lopps = 1;
            this.store.dispatch(new AuthAddCredits({ lopps }));
            this.store.dispatch(GameSurveyActions.setGame({ game }));

            this._logEvent(game, 'unprotect_clicked');

            return true;
        } catch (error) {
            await this.toast.present({
                message: this.translate.instant('common.errors.send_data'),
                error,
            });

            return false;
        }
    }

    private _logEvent(game: GameResponseDto, eventName: string): void {
        const {
            restaurantName: restaurant_name,
            restaurantId: restaurant_id,
            id: session_id,
            amount,
        } = game;

        this.analytics.logEvent(eventName, {
            restaurant_name,
            restaurant_id,
            amount,
            session_id,
            ...(eventName === 'cashback_earned' ? { cashbackAmount: game.earning } : {}),
        });
    }

    private async _notEnoughLoppsModal(): Promise<void> {
        const event = await this.modalService.alert({
            text: 'game.step4.not_enough_lopps.text',
            confirmTxt: 'game.step4.not_enough_lopps.button',
            skipTxt: 'game.step4.not_enough_lopps.skip',
            closeIcon: true,
        });

        if (event === 'CONFIRMED') {
            this.navCtrl.navigateForward('/profile/purchase-lopps');
        }
    }
}
