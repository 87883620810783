import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Capacitor, Device } from '@capacitor/core';
import { LogDto } from 'src/services/lopp-api/log/log.dtos';
import { environment } from '../../../environments/environment';

@Injectable()
export class LogService {
    baseUrl = environment.server.baseUri + '/log';

    constructor(private http: HttpClient) {}

    async log(data: unknown): Promise<void> {
        const { uuid } = await Device.getInfo();

        const dto: LogDto = {
            platform: Capacitor.platform,
            version:
                Capacitor.platform === 'ios' ? environment.iosVersion : environment.androidVersion,
            log: JSON.stringify(data),
            uuid,
        };

        return this.http.post<void>(`${this.baseUrl}`, dto).toPromise();
    }
}
