import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    ViewChild,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { AnimationController, Animation } from '@ionic/angular';
import { ModalService } from 'src/services/modal.service';

@Component({
    selector: 'app-shared-alert-modal',
    templateUrl: './alert-modal.component.html',
    styleUrls: ['./alert-modal.component.scss'],
})
export class AlertModalComponent implements AfterViewInit {
    @Input() title: string;
    @Input() text?: string;
    @Input() texts?: string[];
    @Input() icon: string;
    @Input() confirmTxt: string;
    @Input() skipTxt: string;
    @Input() closeIcon = true;
    @Input() autoclose = false;
    @Input() isLoading = false;
    @Input() checkbox?: string;

    @Output() onClose: EventEmitter<void> = new EventEmitter<void>();
    @Output() onConfirm: EventEmitter<void> = new EventEmitter<void>();
    @Output() onSkip: EventEmitter<void> = new EventEmitter<void>();

    @ViewChild('overlay') overlayRef: ElementRef;
    @ViewChild('modal') modalRef: ElementRef;

    animation: Animation;
    animationDuration = 200;
    showOverlay = true;

    checkForm = this.fb.group({ checkbox: false });

    constructor(
        private modalService: ModalService,
        private animationCtrl: AnimationController,
        private fb: FormBuilder,
    ) {}

    ngAfterViewInit(): void {
        const overlayAnimation: Animation = this.animationCtrl
            .create()
            .addElement(this.overlayRef.nativeElement)
            .fromTo('opacity', 0, 0.5);

        const modalAnimation: Animation = this.animationCtrl
            .create()
            .addElement(this.modalRef.nativeElement)
            .fromTo('top', '100%', 0);

        this.animation = this.animationCtrl
            .create()
            .duration(this.animationDuration)
            .easing('ease-in-out')
            .addAnimation([overlayAnimation, modalAnimation]);

        this.animation.play();
    }

    async close(): Promise<void> {
        await this.animation.direction('reverse').play();
        this.showOverlay = false;
        this.modalService.closeModal();
    }

    confirm(): void {
        if (this.checkbox && this.checkForm.get('checkbox').value === true) {
            this.skip();
        } else {
            this.onConfirm.emit();
            this.autoclose && this.close();
        }
    }

    skip(): void {
        this.onSkip.emit();
        this.autoclose && this.close();
    }
}
