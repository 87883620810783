import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-shared-button',
    templateUrl: './button.component.html',
    styleUrls: [ './button.component.scss' ],
})
export class ButtonComponent {

    @Input() text?: string;
    @Input() icon?: string;
    @Input() disabled = false;
    @Input() hasShadow = false;
    @Input() isLoading = false;
    @Input() transparent = false;
    @Input() modal = false;
    @Input() color: 'yellow' | 'blue' = 'blue';

    // tslint:disable-next-line: no-any
    @Output() buttonClicked: EventEmitter<void> = new EventEmitter<void>();

}
