import 'reflect-metadata';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { getAllDataFromLocalForage, default as localForage } from 'ngrx-store-persist';
import consolere from 'console-remote-client';

if (environment.consoleRemoteClient.enabled) {
    const { server, channel } = environment.consoleRemoteClient;
    consolere.connect({
        server,
        channel,
        redirectDefaultConsoleToRemote: true,
        disableDefaultConsoleOutput: false,
    });
}

if (environment.production) {
    enableProdMode();
}

getAllDataFromLocalForage({
    driver: localForage.LOCALSTORAGE,
    keys: ['location', 'auth', 'restaurants', 'settings', 'gameSurvey'],
}).then(() => {
    platformBrowserDynamic()
        .bootstrapModule(AppModule)
        .catch((err) => console.log(err));
});
