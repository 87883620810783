import { Component, EventEmitter, Input, Output } from '@angular/core';
import { HapticService } from 'src/services/haptic.service';

@Component({
    selector: 'app-button-3d',
    templateUrl: './button-3d.component.html',
    styleUrls: ['./button-3d.component.scss'],
})
export class Button3dComponent {
    @Input() isLoading = false;
    @Input() disabled = false;
    @Input() big = false;
    @Input() subtitle: string;
    @Input() lopps: number;
    @Input() badge: string;
    @Input() color: 'black' | 'white';
    @Input() arrow: 'left' | 'right';

    @Output() onClick = new EventEmitter<void>();
    @Output() onDisabledClick = new EventEmitter<void>();

    constructor(private hapticService: HapticService) {}

    click(): void {
        this.disabled ? this.onDisabledClick.emit() : this.onClick.emit();
        if (!this.disabled) {
            this.hapticService.mediumImpact();
        }
    }
}
