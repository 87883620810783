import { ForgotPasswordModal } from './forgot-password/forgot-password.modal';
import { LoginModal } from './login/login.modal';
import { ResetPasswordModal } from './reset-password/reset-password.modal';
import { SignupModal } from './signup/signup.modal';

export const LOGIN_MODALS = [
    LoginModal,
    SignupModal,
    ForgotPasswordModal,
    ResetPasswordModal,
];
