import { ThrowStmt } from '@angular/compiler';
import { Component, Input, ViewChild } from '@angular/core';
import { IonSlides } from '@ionic/angular';
import { ModalService } from 'src/services/modal.service';

@Component({
    selector: 'app-restautants-gallery-modal',
    templateUrl: './gallery.modal.html',
    styleUrls: [ './gallery.modal.scss' ],
})
export class GalleryModal {

    @Input() images: string[];
    @Input() activeIndex: number;

    @ViewChild(IonSlides) slider: IonSlides;

    ionViewWillEnter(): void {
        this.slider.update();
    }

}
