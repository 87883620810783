import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LoadingController } from '@ionic/angular';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { GameService } from 'src/app/game/game.service';
import { SignupModal } from 'src/app/login/modals/signup/signup.modal';
import { GalleryModal } from 'src/app/restaurants/modals/gallery/gallery.modal';
import { AnalyticsService } from 'src/services/firebase/analytics/index.service';
import { RestaurantDetailResponseDto } from 'src/services/lopp-api/restaurant/index.dtos';
import { RestaurantService } from 'src/services/lopp-api/restaurant/index.service';
import { ModalService } from 'src/services/modal.service';
import { AuthState } from 'src/stores/auth/index.state';
import { LocationSelectors } from 'src/stores/location/index.selectors';
import { Location } from '../../../../../services/geolocation';
import { Utils } from '../../../utils';

@Component({
    selector: 'app-shared-restaurant-detail',
    templateUrl: './restaurant-detail.component.html',
    styleUrls: ['./restaurant-detail.component.scss'],
})
export class RestaurantDetailComponent implements OnInit, OnDestroy {
    @Input() viewDidEnter$: Subject<void>;
    @Input() viewWillLeave$: Subject<void>;

    onDestroy$ = new Subject<void>();

    restaurant: RestaurantDetailResponseDto;

    isLocationEnabled = false;
    isButtonLoading = false;

    // used to define virtual map even in debug mode
    location: Location = Utils.fixedLocation();

    hideFloatingButton = true;

    constructor(
        private gameService: GameService,
        private route: ActivatedRoute,
        private modalService: ModalService,
        private restaurantService: RestaurantService,
        private loadingController: LoadingController,
        private store: Store<{ auth: AuthState }>,
        private analytics: AnalyticsService,
    ) {}

    async ngOnInit(): Promise<void> {
        const loading = await this.loadingController.create({
            spinner: 'crescent',
            mode: 'ios',
        });

        const location = await this.store
            .select(LocationSelectors.selectCoords)
            .pipe(take(1))
            .toPromise();

        this.route.params
            .pipe(
                tap(() => void loading.present()),
                switchMap(({ id }) => {
                    return this.restaurantService.getDetail(id);
                }),
                map((restaurant) => {
                    restaurant.distance = restaurant.getDistance(location);

                    return restaurant;
                }),
                tap(() => void loading.dismiss()),
                takeUntil(this.onDestroy$),
            )
            .subscribe((restaurant) => {
                this.restaurant = restaurant;

                const { id: restaurant_id, name: restaurant_name } = restaurant;
                this.analytics.logEvent('restaurant_viewed', { restaurant_name, restaurant_id });
            });

        this.viewDidEnter$.subscribe(() => {
            this.hideFloatingButton = false;
        });

        this.viewWillLeave$.subscribe(() => {
            // this.hideFloatingButton = true;
        });
    }

    async launchGame(): Promise<void> {
        // this.store.pipe(select('auth'), take(1)).subscribe(({ isLogged }) => {
        //     if (!isLogged) {
        //         this.modalService.openIonModal({ component: SignupModal });
        //         return;
        //     }
        //     this.gameService.launchGame(this.restaurant.name, this.restaurant.googlePlaceId);
        // });
        // if (!this.gameService.isGameLoading) {
        //     this.isButtonLoading = true;
        //     await this.gameService.selectRestaurant(this.restaurant);
        //     this.isButtonLoading = false;
        // }
    }

    openImage(index: number): void {
        this.modalService.openIonModal({
            component: GalleryModal,
            componentProps: {
                images: this.restaurant.additionalPictures,
                activeIndex: index,
            },
            swipeToClose: true,
        });
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }
}
