import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Plugins } from '@capacitor/core';
import { Platform } from '@ionic/angular';
const { Keyboard } = Plugins;
@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit {
    showTabBar = true;

    constructor(private cd: ChangeDetectorRef, private platform: Platform) {}

    ngOnInit(): void {
        if (this.platform.is('capacitor')) {
            Keyboard.addListener('keyboardWillShow', () => {
                this.showTabBar = false;
                this.cd.detectChanges();
            });
            Keyboard.addListener('keyboardDidHide', () => {
                this.showTabBar = true;
                this.cd.detectChanges();
            });
        }
    }
}
