import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { LocalStorageService } from '../../local-storage/index.service';
import { PaymentInfoResponseDto, UserResponseDto } from '../user/user.dtos';
import { IbanDto, SetPaypalDto } from './index.dtos';

enum Endpoint {
    UploadIdCard = '/upload-id-card',
    CheckIban = '/check-iban',
    UploadIban = '/upload-iban',
    CashOut = '/cash-out',
    SetPaypal = '/set-paypal',
}

@Injectable()
export class PaymentsService {
    baseUrl = environment.server.baseUri + '/payment';

    constructor(private http: HttpClient, private storage: LocalStorageService) {}

    /**
     * Upload ID card
     */
    async uploadIdCard(file: File | Blob): Promise<PaymentInfoResponseDto> {
        const data = new FormData();
        data.append('file', file);

        return this.http
            .post<PaymentInfoResponseDto>(this.baseUrl + Endpoint.UploadIdCard, data)
            .toPromise();
    }

    async checkIban(dto: IbanDto): Promise<boolean> {
        return this.http.post<boolean>(this.baseUrl + Endpoint.CheckIban, dto).toPromise();
    }

    /**
     * Upload ID card
     */
    async uploadIban(dto: IbanDto, file: File | Blob): Promise<PaymentInfoResponseDto> {
        if (dto.bic.length === 8) {
            dto.bic = dto.bic + 'XXX';
        }

        const data = new FormData();
        data.append('file', file);
        data.append('accountHolder', dto.accountHolder);
        data.append('bic', dto.bic);
        data.append('number', dto.number);

        return this.http
            .post<PaymentInfoResponseDto>(this.baseUrl + Endpoint.UploadIban, data)
            .toPromise();
    }

    /**
     * Set PayPal
     */
    async setPaypal(dto: SetPaypalDto): Promise<PaymentInfoResponseDto> {
        return this.http
            .post<PaymentInfoResponseDto>(this.baseUrl + Endpoint.SetPaypal, dto)
            .toPromise();
    }

    /**
     * Get back earnings
     */
    cashOut(otpRequestId: string, validationCode: number): Observable<UserResponseDto> {
        const endpoint = `${this.baseUrl}${Endpoint.CashOut}/${otpRequestId}/${validationCode}`;

        return this.http.post<UserResponseDto>(endpoint, null);
    }
}
