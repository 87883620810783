import { createReducer, on } from '@ngrx/store';
import { LocationActions } from 'src/stores/location/index.actions';
import { LocationState } from 'src/stores/location/index.state';

export const initialState: LocationState = {
    status: 'DISABLED',
    coords: null,
};

export const locationReducer = createReducer(
    initialState,
    on(LocationActions.enable, (state) => ({ ...state, status: 'ENABLED' })),
    on(LocationActions.disable, (state) => ({ ...state, status: 'DISABLED' })),
    on(LocationActions.debugMode, (state) => ({ ...state, status: 'DEBUG' })),
    on(LocationActions.update, (state, { coords }) => ({ ...state, coords })),
);
