import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { environment } from '../../environments/environment';

@Injectable()
export class ToastService {
    constructor(private toastController: ToastController) {}

    async present(options: {
        message: string;
        duration?: number;
        color?: string;
        // tslint:disable-next-line: no-any
        error?: any;
    }): Promise<void> {
        const { message, duration, color, error } = options;

        let errorMessage: string;
        if (error && error.message && !environment.production) {
            errorMessage = error.message;
        }

        const toast = await this.toastController.create({
            ...(errorMessage
                ? { message: `${message} (<b>Stacktrace</b> : ${errorMessage})` }
                : { message }),
            duration: duration || 4000,
            mode: 'ios',
            color: color || 'danger',
            position: 'top',
        });
        toast.present();
    }
}
