/**
 * API errors mapping
 */
export const API_ERRORS = {
    PHONE_NUMBER_ALREADY_EXISTS: 'api_errors.phone_number_already_exists',
    UNKNOWN_REFERRAL_CODE: 'api_errors.unknown_referral_code',
    NO_PAYMENT_INFO: 'api_errors.no_payment_info',
    PAYMENT_INFO_NOT_APPROVED: 'api_errors.payment_info_not_approved',
    INVALID_BILL_AMOUNT: 'api_errors.invalid_bill_amount',
    MIN_AMOUNT_NOT_REACHED: 'api_errors.min_amount_not_reached',
    AMBASSADOR_CANNOT_CASHOUT: 'api_errors.ambassador_cannot_cashout',
};
