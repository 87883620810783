import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalService } from 'src/services/modal.service';

@Component({
    templateUrl: './reset-password.modal.html',
})
export class ResetPasswordModal implements OnInit {

    form: FormGroup;

    isLoading = false;

    showPassword = false;
    showPasswordConfirmation = false;

    constructor(
        private modalService: ModalService,
    ) { }

    ngOnInit(): void {
        this.form = new FormGroup({
            password: new FormControl(null, Validators.required),
            confirmation: new FormControl(null, Validators.required),
        });
    }

    async submit(): Promise<void> {
        if (this.isLoading) return;
        this.isLoading = true;
        this.modalService.dismissIonModal();
    }
}
