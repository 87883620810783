import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { TutorialModal } from './tutorial.modal';

@NgModule({
    imports: [
        SharedModule,
    ],
    declarations: [
        TutorialModal,
    ],
    exports: [
        TutorialModal,
    ],
})
export class TutorialModule { }
