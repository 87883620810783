import {
    Component,
    EventEmitter,
    forwardRef,
    Host,
    HostBinding,
    Input,
    OnDestroy,
    OnInit,
    Optional,
    Output,
    SkipSelf,
} from '@angular/core';
import {
    ControlContainer,
    ControlValueAccessor,
    FormControl,
    NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export interface ChoiceOption<T = unknown> {
    value: unknown;
    label: string;
}

@Component({
    selector: 'app-forms-choice',
    templateUrl: './choice.component.html',
    styleUrls: ['./choice.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ChoiceComponent),
            multi: true,
        },
    ],
})
export class ChoiceComponent implements OnInit, OnDestroy, ControlValueAccessor {
    @Input() formControlName: string;
    @Input() options: ChoiceOption[];

    @Output() touched = new EventEmitter<void>();

    onDestroy$ = new Subject<void>();

    control: FormControl;
    selected: ChoiceOption;

    propagateChange: (_: unknown) => void;
    propagateTouch: () => void;

    constructor(@Optional() @Host() @SkipSelf() private controlContainer: ControlContainer) {}

    ngOnInit(): void {
        if (this.controlContainer) {
            if (!this.formControlName) {
                throw new Error('formControlName must be defined!');
            }
            this.control = this.controlContainer.control.get(this.formControlName) as FormControl;
        }
        this.touched.pipe(takeUntil(this.onDestroy$)).subscribe(() => this.propagateTouch());
    }

    registerOnChange(fn: (_: unknown) => void): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.propagateTouch = fn;
    }

    writeValue(value: unknown): void {
        this.selected = this.options.find((item) => item.value === value);
        if (this.selected) {
            this.control?.markAsTouched();
        }
    }

    setDisabledState(isDisabled: boolean): void {
        isDisabled ? this.control?.disable() : this.control?.enable();
    }

    select(option: ChoiceOption): void {
        this.selected = option;
        this.propagateChange(option.value);
        this.control.markAsTouched();
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }
}
