import { Injectable } from '@angular/core';
import { ToastService } from 'src/services/toast/index.service';
import { TranslateService } from '@ngx-translate/core';
import { UsersService } from 'src/services/lopp-api/user/user.service';
import { AuthService } from 'src/services/firebase/auth/auth.service';
import { ModalService } from 'src/services/modal.service';
import { AnalyticsService } from 'src/services/firebase/analytics/index.service';
import { API_ERRORS } from 'src/services/lopp-api';
import {
    AuthLanguage,
    AuthLogin,
    AuthSetIsSigningUp,
    AuthSetProfile,
} from 'src/stores/auth/index.actions';
import { Store } from '@ngrx/store';
import { PushNotificationService } from 'src/services/push-notification/index.service';
import { AuthState } from 'src/stores/auth/index.state';
import { RegisterDto } from 'src/services/lopp-api/user/user.dtos';

@Injectable()
export class SignupService {
    constructor(
        private toast: ToastService,
        private translate: TranslateService,
        private usersService: UsersService,
        private authService: AuthService,
        private modalService: ModalService,
        private analytics: AnalyticsService,
        private store: Store<{ auth: AuthState }>,
        private pushNotificationService: PushNotificationService,
    ) {}

    async registerUserToFirebase(email: string, password: string): Promise<string> {
        try {
            const token = await this.authService.register({ email, password });

            return token;
        } catch (error) {
            if (error.code === 'auth/email-already-in-use') {
                this.toast.present({
                    message: this.translate.instant('firebase_errors.email_already_in_use'),
                });
            } else if (error.code === 'auth/weak-password') {
                this.toast.present({
                    message: this.translate.instant('firebase_errors.weak-password'),
                });
            } else {
                this.toast.present({
                    message: this.translate.instant('common.errors.signup'),
                    error,
                });
            }

            return null;
        }
    }

    async registerUserToServer(dto: RegisterDto): Promise<boolean> {
        try {
            await this.usersService.register(dto);
            this.analytics.logEvent('sign_up', { provider: dto.provider });
        } catch (error) {
            const firebaseUser = await this.authService.instance().currentUser;
            await firebaseUser.delete();

            const message = API_ERRORS[error.error?.error];
            if (message) {
                this.toast.present({
                    message: this.translate.instant(message),
                });
            } else {
                this.toast.present({
                    message: this.translate.instant('common.errors.signup'),
                    error,
                });
            }

            return false;
        }

        const {
            firstName,
            lastName,
            lopps,
            totalEarnings,
            totalPendingEarnings,
            avatar,
            language,
            isAmbassador,
        } = await this.usersService.profile();

        this.store.dispatch(
            new AuthSetProfile({
                lopps,
                totalEarnings,
                totalPendingEarnings,
                firstName,
                lastName,
                avatar,
                isAmbassador,
            }),
        );

        this.store.dispatch(new AuthLanguage({ language }));

        this.store.dispatch(new AuthLogin());

        return true;
    }

    async checkEmail(
        email: string,
        ignoreMethod: 'apple.com' | 'facebook.com' | 'google.com',
    ): Promise<boolean> {
        let methods = await this.authService.instance().fetchSignInMethodsForEmail(email);
        if (methods.length && methods.indexOf(ignoreMethod) === -1) {
            methods = methods.map((item) =>
                item === 'apple.com' ? 'Apple' : item === 'password' ? 'Email' : 'Gmail',
            );

            this.toast.present({
                message: this.translate.instant('common.errors.signup_wrong_provider', {
                    provider: methods.join('/'),
                }),
            });

            return false;
        }

        return true;
    }
}
