import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ModalService } from 'src/services/modal.service';

@Component({
    selector: 'app-shared-nav-close',
    templateUrl: './nav-close.component.html',
    styleUrls: ['./nav-close.component.scss'],
})
export class NavCloseComponent {
    @Input() color: 'blue' | 'yellow';
    @Input() absolute = false;
    @Input() closeIonModal = true;

    @Output() onClose = new EventEmitter<void>();

    constructor(private modalService: ModalService) {}

    close(): void {
        if (this.closeIonModal) {
            this.modalService.dismissIonModal();
        }
        this.onClose.emit();
    }
}
