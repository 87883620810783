import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Plugins, HapticsImpactStyle, HapticsNotificationType } from '@capacitor/core';
const { Haptics } = Plugins;

@Injectable()
export class HapticService {
    constructor(private platform: Platform) {}

    vibrate(): void {
        Haptics.vibrate();
    }

    lightImpact(): void {
        if (this.platform.is('capacitor')) {
            Haptics.impact({ style: HapticsImpactStyle.Light });
        }
    }

    mediumImpact(): void {
        if (this.platform.is('capacitor')) {
            Haptics.impact({ style: HapticsImpactStyle.Medium });
        }
    }

    heavyImpact(): void {
        if (this.platform.is('capacitor')) {
            Haptics.impact({ style: HapticsImpactStyle.Heavy });
        }
    }

    warningNotification(): void {
        if (this.platform.is('capacitor')) {
            Haptics.notification({ type: HapticsNotificationType.WARNING });
        }
    }

    errorNotification(): void {
        if (this.platform.is('capacitor')) {
            Haptics.notification({ type: HapticsNotificationType.ERROR });
        }
    }

    successNotification(): void {
        if (this.platform.is('capacitor')) {
            Haptics.notification({ type: HapticsNotificationType.SUCCESS });
        }
    }
}
