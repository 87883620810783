import { Injectable } from '@angular/core';
import { Plugins } from '@capacitor/core';
import { FacebookLoginResponse } from '@capacitor-community/facebook-login';
import { ToastService } from 'src/services/toast/index.service';
import { TranslateService } from '@ngx-translate/core';
import { UsersService } from 'src/services/lopp-api/user/user.service';
import { Store } from '@ngrx/store';
import { AuthService } from 'src/services/firebase/auth/auth.service';
import firebase from 'firebase/app';
import { AuthState } from 'src/stores/auth/index.state';
import { RegistrationActions } from 'src/stores/registration/registration.actions';
import { NavController } from '@ionic/angular';
import { SignupService } from 'src/services/signup.service';
const { FacebookLogin } = Plugins;

@Injectable()
export class FacebookLoginService {
    constructor(
        private toast: ToastService,
        private translate: TranslateService,
        private usersService: UsersService,
        private store: Store<{ auth: AuthState }>,
        private authService: AuthService,
        private navCtrl: NavController,
        private signupService: SignupService,
    ) {}

    async login(): Promise<boolean> {
        const credential = await this._getCredendial();
        if (!credential) {
            return false;
        }
        const { email } = await this._getFacebookUser();
        if (!(await this.signupService.checkEmail(email, 'facebook.com'))) {
            return false;
        }

        const { user, additionalUserInfo } = await this.authService
            .instance()
            .signInWithCredential(credential);

        const idToken = await user.getIdToken();
        const hasAccount = await this.usersService.hasAccount(idToken);
        if (hasAccount) {
            this.navCtrl.navigateRoot('/home', { animationDirection: 'forward' });

            return true;
        }

        const profile = additionalUserInfo.profile as {
            first_name: string;
            last_name: string;
            picture: { data: { url: string } };
        };

        this.store.dispatch(
            RegistrationActions.facebookRegistration({
                email,
                firstName: profile.first_name,
                lastName: profile.last_name,
                idToken,
                imageUrl: profile.picture?.data?.url,
            }),
        );
        this.navCtrl.navigateForward(['/registration/register']);

        return false;
    }

    private async _getCredendial(): Promise<firebase.auth.OAuthCredential> {
        const FACEBOOK_PERMISSIONS = ['email', 'public_profile'];
        const { accessToken } = (await FacebookLogin.login({
            permissions: FACEBOOK_PERMISSIONS,
        })) as FacebookLoginResponse;

        if (!accessToken) {
            return null;
        }

        return firebase.auth.FacebookAuthProvider.credential(accessToken.token);
    }

    private async _getFacebookUser(): Promise<{ email: string; id: string }> {
        const facebookUser = (await FacebookLogin.getProfile({
            fields: ['email'],
        })) as { email: string; id: string };

        return facebookUser;
    }
}
