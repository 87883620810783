import { BasicHeaderComponent } from './basic-header/basic-header.component';
import { TabBarComponent } from './tab-bar/tab-bar.component';
import { RESTAURANTS_COMPONENTS } from './restaurants';
import { GAME_COMPONENTS } from './game';
import { ButtonComponent } from './button/button.component';
import { BottomPanelComponent } from './bottom-panel/bottom-panel.component';
import { AlertModalComponent } from './alert-modal/alert-modal.component';
import { BuyLoppsButtonComponent } from './buy-lopps-button/buy-lopps-button.component';
import { PageTitleComponent } from './page-title/page-title.component';
import { NavBackComponent } from './nav-back/nav-back.component';
import { NavCloseComponent } from './nav-close/nav-close.component';
import { SplashScreenComponent } from './splash-screen/splash-screen.component';
import { LocationDisabledComponent } from './location-disabled/location-disabled.component';
import { GameLimitReachedModalComponent } from './game-limit-reached-modal/game-limit-reached-modal.component';
import { BillSelectorComponent } from './bill-selector/bill-selector.component';
import { SelectorItemComponent } from './selector-item/selector-item.component';
import { BadgeComponent } from './badge/badge.component';
import { PhoneNumberInputComponent } from 'src/app/shared/components/phone-number-input/phone-number-input.component';
import { SelectModalComponent } from 'src/app/shared/components/select-modal/select-modal.component';
import { InviteYourFriendsComponent } from 'src/app/shared/components/invite-your-friends/invite-your-friends.component';
import { InviteYourFriendsModalComponent } from 'src/app/shared/components/invite-your-friends-modal/invite-your-friends-modal.component';
import { PhoneNumberVerificationComponent } from 'src/app/shared/components/phone-number-verification/phone-number-verification.component';
import { SetPhoneNumberModalComponent } from 'src/app/shared/components/set-phone-number-modal/set-phone-number-modal.component';
import { NEW_COMPONENTS } from 'src/app/shared/components/new';
import { NavHeaderComponent } from 'src/app/shared/components/new/nav-header/nav-header.component';
import { CircleBadgeComponent } from 'src/app/shared/components/new/circle-badge/circle-badge.component';
import { FORM_COMPONENTS } from 'src/app/shared/components/forms';
import { NewTabBarComponent } from 'src/app/shared/components/new-tab-bar/new-tab-bar.component';
import { LeaveModal } from 'src/app/shared/components/leave-modal/leave.modal';
import { Button3dComponent } from 'src/app/shared/components/button-3d/button-3d.component';
import { HeaderBadgeComponent } from 'src/app/shared/components/header-badge/header-badge.component';
import { HeaderButtonComponent } from 'src/app/shared/components/header-button/header-button.component';
import { HeaderComponent } from 'src/app/shared/components/header/header.component';
import { InfoBoxComponent } from 'src/app/shared/components/info-box/info-box.component';
import { ButtonBadgeComponent } from 'src/app/shared/components/button-badge/button-badge.component';

export const SHARED_COMPONENTS = [
    ...RESTAURANTS_COMPONENTS,
    ...GAME_COMPONENTS,
    ...NEW_COMPONENTS,
    ...FORM_COMPONENTS,
    TabBarComponent,
    BasicHeaderComponent,
    NavBackComponent,
    NavCloseComponent,
    ButtonComponent,
    BottomPanelComponent,
    AlertModalComponent,
    BuyLoppsButtonComponent,
    PageTitleComponent,
    SplashScreenComponent,
    LocationDisabledComponent,
    GameLimitReachedModalComponent,
    BillSelectorComponent,
    SelectorItemComponent,
    BadgeComponent,
    PhoneNumberInputComponent,
    SelectModalComponent,
    InviteYourFriendsComponent,
    InviteYourFriendsModalComponent,
    PhoneNumberVerificationComponent,
    SetPhoneNumberModalComponent,
    NavHeaderComponent,
    CircleBadgeComponent,
    NewTabBarComponent,
    LeaveModal,
    Button3dComponent,
    ButtonBadgeComponent,
    HeaderBadgeComponent,
    HeaderButtonComponent,
    HeaderComponent,
    InfoBoxComponent,
];
