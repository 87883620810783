import { ComponentFactoryResolver, Injectable, Type, ViewContainerRef } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ModalOptions } from '@ionic/core';
import { AlertModalComponent } from 'src/app/shared/components/alert-modal/alert-modal.component';
import { Plugins } from '@capacitor/core';
import { RemoteConfigService } from 'src/services/firebase/remote-config/index.service';
const { Browser } = Plugins;

interface AlertOptions {
    title?: string;
    text?: string;
    texts?: string[];
    icon?: string;
    confirmTxt: string;
    skipTxt?: string;
    closeIcon?: boolean;
    checkbox?: string;
}

type ModalEvent = 'CONFIRMED' | 'SKIPPED' | 'CLOSED';

// tslint:disable: no-any
@Injectable()
export class ModalService {
    modalContainer: ViewContainerRef;
    ionModals: HTMLIonModalElement[] = [];
    modalCount = 0;

    constructor(
        private modalCtrl: ModalController,
        private componentFactoryResolver: ComponentFactoryResolver,
        private remoteConfig: RemoteConfigService,
    ) {}

    async openIonModal(options: ModalOptions): Promise<HTMLIonModalElement> {
        options.mode = 'ios';
        options.keyboardClose = false;
        options.backdropDismiss = false;
        const modal = await this.modalCtrl.create(options);
        modal.present();

        this.ionModals.push(modal);

        return modal;
    }

    openModal<T>(component: Type<T>, componentProps?: { [key: string]: any }): T {
        const childComponent = this.componentFactoryResolver.resolveComponentFactory<T>(component);
        const instance = this.modalContainer.createComponent<T>(childComponent).instance;

        for (const prop in componentProps) {
            if (componentProps.hasOwnProperty(prop)) {
                instance[prop] = componentProps[prop];
            }
        }

        ++this.modalCount;

        return instance;
    }

    closeModal(): void {
        --this.modalCount;
        if (this.modalCount === 0) {
            this.modalContainer.clear();
        }
    }

    dismissIonModal(data?: any, role?: string): void {
        const modal = this.ionModals.pop();
        modal.dismiss(data, role);
    }

    async alert(options: AlertOptions): Promise<ModalEvent> {
        return new Promise((resolve) => {
            const instance = this.openModal(AlertModalComponent, options);
            instance.autoclose = true;
            instance.onConfirm.subscribe(() => {
                resolve('CONFIRMED');
            });
            instance.onSkip.subscribe(() => {
                resolve('SKIPPED');
            });
            instance.onClose.subscribe(() => {
                resolve('CLOSED');
            });
        });
    }

    async updateModal(type: 'ios' | 'android'): Promise<void> {
        const instance = this.openModal(AlertModalComponent, {
            title: 'common.app_updated.title',
            text: 'common.app_updated.text',
            icon: 'lopp.svg',
            confirmTxt: 'common.app_updated.button',
            closeIcon: false,
        });
        instance.autoclose = false;
        let url: string;
        if (type === 'ios') {
            url = await this.remoteConfig.getString('APPSTORE_URL');
        } else if (type === 'android') {
            url = await this.remoteConfig.getString('PLAYSTORE_URL');
        }
        instance.onConfirm.subscribe(() => {
            void Browser.open({ url });
        });
    }
}
