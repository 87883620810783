import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { select, Store } from '@ngrx/store';
import { AuthState } from '../../../../../stores/auth/index.state';

@Component({
    selector: 'app-shared-game-header',
    templateUrl: './game-header.component.html',
    styleUrls: ['./game-header.component.scss'],
})
export class GameHeaderComponent implements OnInit {
    @Input() type: 'game' | 'home';
    @Input() withNavBack = false;

    lopps: number;
    totalEarnings: number;
    avatar: string;
    platform = Capacitor.platform;

    constructor(public router: Router, private store: Store<{ auth: AuthState }>) {}

    async ngOnInit(): Promise<void> {
        this.store.pipe(select('auth')).subscribe(({ lopps, totalEarnings, avatar }) => {
            this.lopps = lopps;
            this.totalEarnings = totalEarnings;
            this.avatar = avatar;
        });
    }
}
