import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NavController } from '@ionic/angular';
import { ModalService } from 'src/services/modal.service';

@Component({
    selector: '',
    templateUrl: './leave.modal.html',
    styleUrls: ['./leave.modal.scss'],
})
export class LeaveModal {
    @Input() title: string;
    @Input() subtitle: string;
    @Input() resume: string;
    @Input() confirm: string;

    constructor(private modalService: ModalService, private navCtrl: NavController) {}

    close(confirm: boolean) {
        this.modalService.dismissIonModal(confirm);
    }
}
