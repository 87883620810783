import { AfterViewInit, Directive, ElementRef, EventEmitter, HostBinding, Input, OnDestroy, Output } from '@angular/core';
import { Gesture, GestureController, GestureDetail } from '@ionic/angular';
import * as $ from 'jquery';

@Directive({
    selector: '[appCarousel]',
})
export class CarouselDirective implements AfterViewInit, OnDestroy {

    @HostBinding('className') className = 'Carousel';

    @Input() scroll: boolean;
    @Output() scrollChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    gesture: Gesture;

    replaceTimeout: NodeJS.Timeout;

    constructor(
        private gestureCtrl: GestureController,
        private elementRef: ElementRef,
    ) { }

    ngAfterViewInit(): void {
        const carousel = $(this.elementRef.nativeElement);
        let scrollStart = 0;

        this.gesture = this.gestureCtrl.create({
            gestureName: 'my-gesture',
            el: this.elementRef.nativeElement,
            onStart: () => {
                carousel.css('overflow-x', 'scroll');
                scrollStart = carousel.scrollLeft();
                this.scrollChange.emit(false);
                if (this.replaceTimeout) {
                    clearTimeout(this.replaceTimeout);
                }
            },
            onMove: (detail: GestureDetail) => {
                const scrollLeft = scrollStart + detail.deltaX * -1;
                carousel.scrollLeft(scrollLeft);
            },
            onEnd: (detail: GestureDetail) => {
                this.replaceTimeout = setTimeout(() => {
                    this.replaceSlides(carousel, detail);
                }, 500);

                carousel.css('overflow-x', 'hidden');
                this.scrollChange.emit(true);
            },
        }, true);

        this.gesture.enable();
    }

    ngOnDestroy(): void {
        this.gesture.destroy();
    }

    replaceSlides(carousel: JQuery, detail: GestureDetail): void {
        const paddingLeft = parseFloat(carousel.css('paddingLeft'));

        // deltaX < 0 -> right
        // deltaX > 0 -> left

        if (detail.deltaX < 0) {
            carousel.children().each((index: number, element: HTMLElement) => {
                const { left } = $(element).position();
                if (left > 0) {
                    const scrollLeft = carousel.scrollLeft() + left - paddingLeft;
                    carousel.animate({ scrollLeft });

                    return false;
                }
            });
        } else {
            $(carousel.children().get().reverse()).each((index: number, element: HTMLElement) => {
                const { left } = $(element).position();
                if (left < 0) {
                    const scrollLeft = carousel.scrollLeft() + left - paddingLeft;
                    carousel.animate({ scrollLeft });

                    return false;
                }
            });
        }
    }

}
