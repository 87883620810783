import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from '../../../../environments/environment';
import { ModalService } from 'src/services/modal.service';
import { AuthService } from '../../../../services/firebase/auth/auth.service';
import { ForgotPasswordDto } from '../../../../services/firebase/auth/auth.dtos';
import { ToastService } from '../../../../services/toast/index.service';
import { TranslateService } from '@ngx-translate/core';
import { NetworkService } from 'src/services/network.service';

@Component({
    templateUrl: './forgot-password.modal.html',
})
export class ForgotPasswordModal implements OnInit {
    form: FormGroup;

    isLoading = false;

    constructor(
        private modalService: ModalService,
        private authService: AuthService,
        private toast: ToastService,
        private translate: TranslateService,
        private networkService: NetworkService,
    ) {}

    ngOnInit(): void {
        this.form = new FormGroup({
            email: new FormControl(null, Validators.required),
        });

        // if (!environment.production) {
        //     this.form.patchValue({
        //         email: 'zmehanna@gmail.com',
        //     }, { emitEvent: true });
        // }
    }

    async submit(): Promise<void> {
        if (this.isLoading) return;

        if (!(await this.networkService.checkNetWorkStatus())) {
            return;
        }

        this.isLoading = true;

        try {
            const dto: ForgotPasswordDto = this.form.value;

            const providers = await this.authService.providers(dto.email);
            if (providers.length) {
                const isPasswordAccount = providers.find((provider) => provider === 'password');
                if (!isPasswordAccount) {
                    await this.toast.present({
                        message: this.translate.instant('common.errors.reset_password_provider'),
                    });

                    this.isLoading = false;

                    return;
                }
            }

            await this.authService.forgotPassword(dto);
        } catch (error) {
            await this.toast.present({
                message: this.translate.instant('common.errors.send_data'),
                error,
            });
        } finally {
            // show success modal even if email isn't found
            const options = {
                title: 'login.forgot_password.confirmation.title',
                text: 'login.forgot_password.confirmation.text',
                icon: 'email_sent.svg',
                confirmTxt: 'login.forgot_password.confirmation.button',
                closeIcon: false,
            };
            await this.modalService.alert(options);
            this.modalService.dismissIonModal();

            this.isLoading = false;
        }
    }
}
