import { createReducer, on } from '@ngrx/store';
import { AuthSource } from 'src/services/lopp-api/user/user.dtos';
import { RegistrationActions } from 'src/stores/registration/registration.actions';
import { RegistrationState } from 'src/stores/registration/registration.state';

export const initialState: RegistrationState = {
    provider: null,
    idToken: null,
    firstName: null,
    lastName: null,
    email: null,
    imageUrl: null,
};

export const registrationReducer = createReducer(
    initialState,
    on(RegistrationActions.clear, () => ({ ...initialState })),
    on(RegistrationActions.appleRegistration, (_, { firstName, lastName, email, idToken }) => ({
        ...initialState,
        provider: 'APPLE' as AuthSource,
        firstName,
        lastName,
        email,
        idToken,
    })),
    on(
        RegistrationActions.facebookRegistration,
        (_, { firstName, lastName, email, idToken, imageUrl }) => ({
            ...initialState,
            provider: 'FACEBOOK' as AuthSource,
            firstName,
            lastName,
            email,
            idToken,
            imageUrl,
        }),
    ),
    on(
        RegistrationActions.googleRegistration,
        (_, { firstName, lastName, email, idToken, imageUrl }) => ({
            ...initialState,
            provider: 'GOOGLE' as AuthSource,
            firstName,
            lastName,
            email,
            idToken,
            imageUrl,
        }),
    ),
    on(RegistrationActions.emailRegistration, () => ({
        ...initialState,
        provider: 'EMAIL' as AuthSource,
    })),
);
