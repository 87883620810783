import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/guards/auth.guard';
import { LayoutComponent } from './layout/layout.component';

const routes: Routes = [
    { path: '', redirectTo: 'home', pathMatch: 'full' },
    {
        path: 'registration',
        loadChildren: () =>
            import('./registration/registration.module').then((m) => m.RegistrationModule),
    },
    {
        path: '',
        component: LayoutComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: 'home',
                loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
            },
            {
                path: 'restaurants',
                loadChildren: () =>
                    import('./restaurants/restaurants.module').then((m) => m.RestaurantsModule),
            },
            {
                path: 'wallet',
                loadChildren: () => import('./wallet/wallet.module').then((m) => m.WalletModule),
            },
            {
                path: 'profile',
                loadChildren: () => import('./profile/profile.module').then((m) => m.ProfileModule),
            },
        ],
    },
    {
        path: 'game',
        canActivate: [AuthGuard],
        loadChildren: () => import('./game/game.module').then((m) => m.GameModule),
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            preloadingStrategy: PreloadAllModules,
            relativeLinkResolution: 'legacy',
        }),
    ],
    exports: [RouterModule],
    providers: [AuthGuard],
})
export class AppRoutingModule {}
