import { Injectable } from '@angular/core';
import { Plugins } from '@capacitor/core';
import { TranslateService } from '@ngx-translate/core';
import { ModalService } from 'src/services/modal.service';
const { Network } = Plugins;

@Injectable()
export class NetworkService {
    constructor(private modalService: ModalService, private translate: TranslateService) {}

    public async checkNetWorkStatus(): Promise<boolean> {
        const { connected } = await Network.getStatus();
        if (!connected) {
            this._showNetworkUnavailableModal();
            return false;
        }

        return true;
    }

    private _showNetworkUnavailableModal(): void {
        const options = {
            title: this.translate.instant('common.errors.no_internet_connection.title'),
            text: this.translate.instant('common.errors.no_internet_connection.text'),
            confirmTxt: this.translate.instant('common.errors.no_internet_connection.button'),
        };
        void this.modalService.alert(options);
    }
}
