import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Platform } from '@ionic/angular';

@Component({
    selector: 'app-shared-lopp-button',
    templateUrl: './lopp-button.component.html',
    styleUrls: ['./lopp-button.component.scss'],
})
export class LoppButtonComponent {
    @Input() type: 'mini' | 'small' | 'normal' | 'floating';
    @Input() floatingHidden = false;
    @Input() isLoading = false;
    @Input() partnerMode = false;

    @Output() buttonClicked: EventEmitter<void> = new EventEmitter<void>();

    constructor(public platform: Platform) {}
}
