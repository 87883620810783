import { Component, Input } from '@angular/core';
import { NavController } from '@ionic/angular';

@Component({
    selector: 'app-shared-nav-back',
    templateUrl: './nav-back.component.html',
    styleUrls: ['./nav-back.component.scss'],
})
export class NavBackComponent {
    @Input() color: 'blue' | 'yellow' | 'transparent';
    @Input() backUrl: string | string[];

    constructor(private navCtrl: NavController) {}

    goBack(): void {
        if (this.backUrl) {
            this.navCtrl.navigateBack(this.backUrl);
        } else {
            this.navCtrl.back();
            // this.navCtrl.pop();
        }
    }
}
