import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import {
    PackageResponseDto,
    PromoCodeResponseDto,
    PurchaseAndroidDto,
    PurchaseIOSDto,
    PurchaseResponseDto,
} from './index.dtos';

enum Endpoint {
    Purchase = '/purchase/',
    GetPromoCode = '/promo-code/',
}

@Injectable()
export class PackagesService {
    baseUrl = environment.server.baseUri + '/package';

    constructor(private http: HttpClient) {}

    async list(platform: 'ios' | 'android'): Promise<PackageResponseDto[]> {
        return this.http.get<PackageResponseDto[]>(this.baseUrl + '/' + platform).toPromise();
    }

    async purchase(id: string, amount: number): Promise<number> {
        return this.http
            .post<number>(this.baseUrl + Endpoint.Purchase + id + '/' + amount, {})
            .toPromise();
    }

    async purchaseIOS(dto: PurchaseIOSDto): Promise<PurchaseResponseDto> {
        return this.http
            .post<PurchaseResponseDto>(this.baseUrl + Endpoint.Purchase + 'ios', dto)
            .toPromise();
    }

    async purchaseAndroid(dto: PurchaseAndroidDto): Promise<PurchaseResponseDto> {
        return this.http
            .post<PurchaseResponseDto>(this.baseUrl + Endpoint.Purchase + 'android', dto)
            .toPromise();
    }

    getPurchaseUrl(platform: 'ios' | 'android'): string {
        return this.baseUrl + Endpoint.Purchase + platform;
    }

    getPromoCode(code: string): Observable<PromoCodeResponseDto> {
        return this.http.get<PromoCodeResponseDto>(this.baseUrl + Endpoint.GetPromoCode + code);
    }
}
