// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { IEnvironment } from './environment.interface';

export const environment: IEnvironment = {
    production: false,
    iosVersion: '2.0.2',
    androidVersion: '2.0.2',
    prefillLoginWithHasaname: false,
    enableAnalytics: true,
    showCrashlyticsDebugButton: false,
    showSplashScreen: true,
    server: {
        baseUri: 'https://staging-lopp-api.vaotechnologies.com/app',
    },
    firebase: {
        apiKey: 'AIzaSyCsQwMqTH6rzRLautyVzA0WHWwcfCOGsIQ',
        authDomain: 'lopp-staging.firebaseapp.com',
        projectId: 'lopp-staging',
        storageBucket: 'lopp-staging.appspot.com',
        messagingSenderId: '549296283513',
        appId: '1:549296283513:web:8bcf95ac602052cb9e99a8',
        measurementId: 'G-ZV1SKN3DHD',
    },
    googleMapsApiKey: 'AIzaSyCnest0ZaoZerIDFFYyw7JJ3JeNkxRlTiM',
    consoleRemoteClient: {
        enabled: true,
        server: 'https://consolere.vaotechnologies.com',
        channel: 'lopp-staging',
    },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/dist/zone-error'; // Included with Angular CLI.
