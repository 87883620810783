import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-shared-badge',
    templateUrl: './badge.component.html',
    styleUrls: [ './badge.component.scss' ],
})
export class BadgeComponent {

    @Input() text: string;
    @Input() type: string;
    @Input() icon?: string;

}
