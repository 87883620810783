import { createAction, props } from '@ngrx/store';

const domain = '[REGISTRATION]';

const appleRegistration = createAction(
    `${domain} Apple Registration`,
    props<{ firstName: string; lastName: string; email: string; idToken: string }>(),
);
const facebookRegistration = createAction(
    `${domain} Facebook Registration`,
    props<{
        firstName: string;
        lastName: string;
        email: string;
        idToken: string;
        imageUrl: string;
    }>(),
);
const googleRegistration = createAction(
    `${domain} Google Registration`,
    props<{
        firstName: string;
        lastName: string;
        email: string;
        idToken: string;
        imageUrl: string;
    }>(),
);
const emailRegistration = createAction(`${domain} Email Registration`);
const clear = createAction(`${domain} Clear`);

export const RegistrationActions = {
    appleRegistration,
    facebookRegistration,
    googleRegistration,
    emailRegistration,
    clear,
};
