import { ChangeDetectorRef, Component, Input, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { ModalService } from 'src/services/modal.service';
import * as escapeStringRegexp from 'escape-string-regexp';

export interface SelectOption<T = string> {
    label: string;
    value: T;
}

@Component({
    templateUrl: './select-modal.component.html',
    styleUrls: ['./select-modal.component.scss'],
})
export class SelectModalComponent implements OnInit, OnDestroy {
    @Input() options: SelectOption[] = [];

    displayedOptions: SelectOption[];

    form = this.fb.group({
        query: [''],
    });

    onDestroy$ = new Subject<void>();

    constructor(private fb: FormBuilder, private modalService: ModalService) {
        this.form.valueChanges
            .pipe(debounceTime(200), takeUntil(this.onDestroy$))
            .subscribe(({ query }) => {
                query = escapeStringRegexp(query);
                const regex = new RegExp(query, 'i');
                if (!query || query === '') {
                    this.displayedOptions = this.options;
                } else {
                    this.displayedOptions = this.options.filter(({ label, value }) => {
                        return regex.test(label) || regex.test(value);
                    });
                }
            });
    }

    ngOnInit(): void {
        this.displayedOptions = this.options;
    }

    clearSearch(): void {
        this.form.patchValue({ query: '' });
    }

    select(option: SelectOption): void {
        this.modalService.dismissIonModal(option);
    }

    close(): void {
        this.modalService.dismissIonModal();
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }
}
