import {
    AfterViewInit,
    Component,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';

import { Platform } from '@ionic/angular';
import { AuthService } from '../services/firebase/auth/auth.service';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AuthLogout } from '../stores/auth/index.actions';
import { TranslateService } from '@ngx-translate/core';
import { ModalService } from 'src/services/modal.service';
import {
    Plugins,
    PushNotification,
    PushNotificationToken,
    PushNotificationActionPerformed,
    Capacitor,
    PluginListenerHandle,
} from '@capacitor/core';
import { DevicesService } from '../services/lopp-api/device/index.service';
import { DeviceType } from '../services/lopp-api/device/index.dtos';
import { SplashScreenComponent } from './shared/components/splash-screen/splash-screen.component';
import { PushNotificationService } from '../services/push-notification/index.service';
import { GeolocationService } from 'src/services/geolocation';
import { filter, take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { LocationSelectors } from 'src/stores/location/index.selectors';
import { PlatformActions } from 'src/stores/platform/index.actions';
import { PlatformSelectors } from 'src/stores/platform/index.selectors';
import { InAppPurchaseService } from 'src/services/in-app-purchase.service';
import { RemoteConfigService } from 'src/services/firebase/remote-config/index.service';
import { environment } from 'src/environments/environment';
const { PushNotifications, Device, App } = Plugins;
import * as semver from 'semver';
import { DeviceService } from 'src/services/device.service';
import { GameService } from 'src/app/game/game.service';
import { GameSurveyActions } from 'src/stores/game-survey/game-survey.actions';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('modalContainer', { read: ViewContainerRef }) modalContainer: ViewContainerRef;
    @ViewChild(SplashScreenComponent) splashScreen: SplashScreenComponent;

    // notification listeners
    registered: PluginListenerHandle;
    registeredError: PluginListenerHandle;
    received: PluginListenerHandle;
    actionPerformed: PluginListenerHandle;
    backButton: PluginListenerHandle;
    appStateChanged: PluginListenerHandle;

    onDestroy$ = new Subject<void>();

    constructor(
        private platform: Platform,
        private auth: AuthService,
        private modalService: ModalService,
        private router: Router,
        private store: Store,
        private translate: TranslateService,
        private remoteConfig: RemoteConfigService,
        private devicesService: DevicesService,
        private pushNotification: PushNotificationService,
        private geolocationService: GeolocationService,
        private iapService: InAppPurchaseService,
        private deviceService: DeviceService,
        private gameService: GameService,
    ) {}

    ngOnInit(): void {
        alert('big time le retour !');
        this.initializeApp();
        this.store
            .pipe(PlatformSelectors.selectResume, takeUntil(this.onDestroy$))
            .subscribe(() => {
                this.showUpdateModal();
                this.store
                    .select(LocationSelectors.selectStatus)
                    .pipe(take(1))
                    .subscribe((status) => {
                        if (status === 'ENABLED') {
                            this.geolocationService.update();
                        } else if (status === 'DEBUG') {
                            // this.toast.present({
                            //     message: 'Debug mode enabled, location is in Paris...',
                            // });
                        }
                    });

                this.auth
                    .instance()
                    .authState.pipe(
                        take(1),
                        filter((user) => !!user),
                    )
                    .subscribe((user) => {
                        this.auth.handleFirebaseUser(user);
                    });
            });
    }

    ngAfterViewInit(): void {
        this.modalService.modalContainer = this.modalContainer;
        void this.splashScreen.hideSplashScreen();
    }

    ngOnDestroy(): void {
        if (this.backButton) this.backButton.remove();
        if (this.registered) this.registered.remove();
        if (this.registeredError) this.registeredError.remove();
        if (this.received) this.received.remove();
        if (this.actionPerformed) this.actionPerformed.remove();
        if (this.appStateChanged) this.appStateChanged.remove();

        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    initializeApp(): void {
        this._initInAppPurchases();

        this.platform.ready().then(() => {
            if (this.platform.is('cordova')) {
                // this.statusBar.styleDefault();

                // init notification listeners
                this._initNotificationListeners();

                // listen for Android back button
                this.backButton = App.addListener('backButton', () => {
                    if (
                        [
                            '/registration/welcome',
                            '/home',
                            '/restaurants',
                            '/wallet',
                            '/profile',
                        ].includes(this.router.url)
                    ) {
                        App.exitApp();
                    }
                });

                // listen for app state to detect pending process
                this.appStateChanged = App.addListener('appStateChange', (state) => {
                    if (state.isActive) {
                        this.store.dispatch(PlatformActions.resume());
                    } else {
                        this.store.dispatch(PlatformActions.pause());
                    }
                });
            }

            // set default language
            // this.translate.setDefaultLang('en');

            /**
             * Listen on user auth state
             */
            this.auth
                .instance()
                .authState.pipe(filter((user) => !user))
                .subscribe(async () => {
                    console.log('Auth State NO USER');

                    const language = await this.deviceService.getLanguage();
                    this.translate.use(language.toLowerCase());

                    this.store.dispatch(new AuthLogout());

                    this.pushNotification.unregister();
                });

            this.auth
                .instance()
                .authState.pipe(filter((user) => !!user))
                .subscribe(async (user) => {
                    console.log('Auth State USER', user);
                    const active = await this.gameService.getActiveGame();
                    if (!active) {
                        this.store.dispatch(GameSurveyActions.reset());
                    }
                    this.auth.handleFirebaseUser(user);
                });
        });
    }

    private _initInAppPurchases(): void {
        const platform = Capacitor.getPlatform();
        if (platform === 'ios' || platform === 'android') {
            this.iapService.init(platform);
        } else {
            this.iapService.init('web');
        }
    }

    /**
     * Init notification listeners
     */
    private _initNotificationListeners(): void {
        // on success, we should be able to receive notifications
        this.registered = PushNotifications.addListener(
            'registration',
            async (token: PushNotificationToken) => {
                console.log('Push registration success, token: ' + token.value);
                const deviceType = Capacitor.getPlatform();
                if (['web', 'ios', 'android'].includes(deviceType)) {
                    const type = deviceType.toUpperCase() as DeviceType;

                    try {
                        const { uuid } = await Device.getInfo();
                        const device = await this.devicesService.find(
                            deviceType,
                            uuid,
                            token.value,
                        );
                        if (!device) {
                            await this.devicesService.create({ token: token.value, type, uuid });
                        }
                    } catch (error) {
                        // silent fail
                    }
                }
            },
        );

        // some issue with our setup and push will not work
        this.registeredError = PushNotifications.addListener(
            'registrationError',
            // tslint:disable-next-line: no-any
            (error: any) => {
                console.log('Error on registration: ' + JSON.stringify(error));
            },
        );

        // show us the notification payload if the app is open on our device
        this.received = PushNotifications.addListener(
            'pushNotificationReceived',
            (notification: PushNotification) => {
                console.log('Push received: ' + notification);
            },
        );

        // method called when tapping on a notification
        this.actionPerformed = PushNotifications.addListener(
            'pushNotificationActionPerformed',
            (notification: PushNotificationActionPerformed) => {
                if (notification.actionId === 'tap') {
                    if (notification.notification.data.token) {
                        this.auth.impersonate(notification.notification.data.token);
                    }
                }
                console.log('Push action performed: ' + notification);
            },
        );
    }

    async showUpdateModal(): Promise<void> {
        let type = Capacitor.getPlatform();

        let storeVersion: string;
        let appVersion: string;
        if (type === 'ios') {
            storeVersion = await this.remoteConfig.getString('IOS_APP_VERSION');
            appVersion = environment.iosVersion;
        } else if (type === 'android') {
            storeVersion = await this.remoteConfig.getString('ANDROID_APP_VERSION');
            appVersion = environment.androidVersion;
        } else {
            return;
        }

        if (semver.gte(appVersion, storeVersion)) {
            return;
        }

        this.modalService.updateModal(type);
    }
}
