import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { RestaurantResponseDto } from 'src/services/lopp-api/restaurant/index.dtos';
import { Location } from 'src/services/geolocation';
import { GameService } from 'src/app/game/game.service';

@Component({
    selector: 'app-shared-restaurant-card',
    templateUrl: './restaurant-card.component.html',
    styleUrls: ['./restaurant-card.component.scss'],
})
export class RestaurantCardComponent {
    @ViewChild('name') nameRef: ElementRef;
    @ViewChild('localization') localizationRef: ElementRef;

    @Input() type: 'small' | 'large' | 'panel' | 'map';
    @Input() restaurant: RestaurantResponseDto;
    @Input() biggerImage = false;
    @Input() skeletton = false;
    @Input() isLogged = false;
    @Input() location: Location;

    @Output() buttonClicked = new EventEmitter<RestaurantResponseDto>();

    isLoading = false;

    constructor(private router: Router, private gameService: GameService) {}

    async launchGame(): Promise<void> {
        // const url = this.router.url;
        // if (url.includes('/game/survey/step3')) {
        //     if (!this.gameService.isGameLoading) {
        //         this.isLoading = true;
        //         await this.gameService.selectRestaurant(this.restaurant);
        //         this.isLoading = false;
        //     }
        // } else {
        //     if (!this.gameService.isGameLoading) {
        //         this.buttonClicked.emit(this.restaurant);
        //         this.isLoading = true;
        //         setTimeout(() => {
        //             this.isLoading = false;
        //         }, 2000);
        //     }
        // }
    }

    detail(): void {
        return;
        if (this.router.url.startsWith('/home')) {
            this.router.navigate(['/home', 'restaurant-detail', this.restaurant.googlePlaceId]);
        } else if (this.router.url.startsWith('/game')) {
            this.router.navigate([
                '/game',
                'survey',
                'restaurant-detail',
                this.restaurant.googlePlaceId,
            ]);
        } else {
            this.router.navigate(['/restaurants', 'detail', this.restaurant.googlePlaceId]);
        }
    }

    onImageError(event): void {
        console.log('image error', event);
    }
}
