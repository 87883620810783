import { createReducer, on } from '@ngrx/store';
import { PlatformActions } from 'src/stores/platform/index.actions';
import { PlatformState } from 'src/stores/platform/index.state';

export const initialState: PlatformState = {
    isActive: true,
};

export const platformReducer = createReducer(
    initialState,
    on(PlatformActions.resume, (state) => ({ ...state, isActive: true })),
    on(PlatformActions.pause, (state) => ({ ...state, isActive: false })),
);
