import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { SHARED_COMPONENTS } from './components';
import { SHARED_DIRECTIVES } from './directives';
import { TimesPipe } from './pipes/times.pipe';
import { SHARED_SERVICES } from './services';
import { EllipsisModule } from 'ngx-ellipsis';
import { AngularSvgIconModule } from 'angular-svg-icon';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        IonicModule,
        ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: 'never' }),
        TranslateModule,
        EllipsisModule,
        AngularSvgIconModule,
    ],
    declarations: [...SHARED_COMPONENTS, ...SHARED_DIRECTIVES, TimesPipe],
    providers: [...SHARED_SERVICES],
    exports: [
        /**
         * Modules
         */
        CommonModule,
        IonicModule,
        ReactiveFormsModule,
        TranslateModule,
        EllipsisModule,
        AngularSvgIconModule,

        /**
         * Components
         */
        ...SHARED_COMPONENTS,

        /**
         * Directives
         */
        ...SHARED_DIRECTIVES,

        /**
         * Services
         */
        ...SHARED_SERVICES,

        /**
         * Pipes
         */
        TimesPipe,
    ],
})
export class SharedModule {}
