import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SignupModal } from '../signup/signup.modal';
import { AuthService } from '../../../../services/firebase/auth/auth.service';
import { ModalService } from 'src/services/modal.service';
import { ForgotPasswordModal } from '../forgot-password/forgot-password.modal';
import { ToastService } from '../../../../services/toast/index.service';
import { TranslateService } from '@ngx-translate/core';
import {
    RemoteConfigKeyString,
    RemoteConfigService,
} from '../../../../services/firebase/remote-config/index.service';
import { select, Store } from '@ngrx/store';
import { AuthState } from '../../../../stores/auth/index.state';
import { Language } from '../../../../services/lopp-api/user/user.dtos';
import { Utils } from '../../../shared/utils';
import { Plugins, Capacitor } from '@capacitor/core';
import { environment } from 'src/environments/environment';
import { LoaderService } from 'src/services/loader.service';
import { NetworkService } from 'src/services/network.service';
import { GoogleLoginService } from 'src/services/google-login.service';
import { FacebookLoginService } from 'src/services/facebook-login.service';
import { AppleLoginService } from 'src/services/apple-login.service';
import { AuthSetIsSigningUp } from 'src/stores/auth/index.actions';
const { Browser } = Plugins;

@Component({
    templateUrl: './login.modal.html',
})
export class LoginModal implements OnInit {
    language: Language;
    form: FormGroup;
    showPassword = false;

    isLoading = false;
    platform = Capacitor.platform;

    constructor(
        private modalService: ModalService,
        private authService: AuthService,
        private toast: ToastService,
        private translate: TranslateService,
        private remoteConfig: RemoteConfigService,
        private store: Store<{ auth: AuthState }>,
        private loaderService: LoaderService,
        private networkService: NetworkService,
        private googleLoginService: GoogleLoginService,
        private facebookLoginService: FacebookLoginService,
        private appleLoginService: AppleLoginService,
        private loader: LoaderService,
    ) {}

    ngOnInit(): void {
        this.store.pipe(select('auth')).subscribe(({ language }) => {
            this.language = language;
        });

        this.form = new FormGroup({
            email: new FormControl(null, Validators.required),
            password: new FormControl(null, Validators.required),
        });

        if (environment.prefillLoginWithHasaname) {
            this.form.patchValue(
                {
                    email: 'someone@gmail.com',
                    password: '123456789',
                },
                { emitEvent: true },
            );
        }
    }

    async appleLogin(): Promise<void> {
        if (this.isLoading) return;

        if (!(await this.networkService.checkNetWorkStatus())) {
            return;
        }

        this.isLoading = true;
        this.loader.present();

        try {
            if (await this.appleLoginService.login()) {
                this.modalService.dismissIonModal();
            }
            this.loader.dismiss();
            this.isLoading = false;
        } catch (error) {
            this.isLoading = false;
            this.loader.dismiss();
            this.toast.present({
                message: this.translate.instant('common.errors.get_data'),
                error,
            });
            this.store.dispatch(new AuthSetIsSigningUp({ isSigningUp: false }));
        } finally {
            this.store.dispatch(new AuthSetIsSigningUp({ isSigningUp: false }));
        }
    }

    async facebookLogin(): Promise<void> {
        if (this.isLoading) return;

        if (!(await this.networkService.checkNetWorkStatus())) {
            return;
        }

        this.isLoading = true;
        this.loader.present();

        try {
            if (await this.facebookLoginService.login()) {
                this.modalService.dismissIonModal();
            }
            this.loader.dismiss();
            this.isLoading = false;
        } catch (error) {
            this.isLoading = false;
            this.loader.dismiss();
            this.toast.present({
                message: this.translate.instant('common.errors.get_data'),
                error,
            });
            this.store.dispatch(new AuthSetIsSigningUp({ isSigningUp: false }));
        } finally {
            this.store.dispatch(new AuthSetIsSigningUp({ isSigningUp: false }));
        }
    }

    async googleLogin(): Promise<void> {
        if (this.isLoading) return;

        if (!(await this.networkService.checkNetWorkStatus())) {
            return;
        }

        this.isLoading = true;
        this.loader.present();

        try {
            if (await this.googleLoginService.login()) {
                this.modalService.dismissIonModal();
            }
            this.loader.dismiss();
            this.isLoading = false;
        } catch (error) {
            this.isLoading = false;
            this.loader.dismiss();
            this.toast.present({
                message: this.translate.instant('common.errors.get_data'),
                error,
            });
            this.store.dispatch(new AuthSetIsSigningUp({ isSigningUp: false }));
        } finally {
            this.store.dispatch(new AuthSetIsSigningUp({ isSigningUp: false }));
        }
    }

    signup(): void {
        this.modalService.dismissIonModal();
        this.modalService.openIonModal({ component: SignupModal });
    }

    forgotPassword(): void {
        this.modalService.openIonModal({ component: ForgotPasswordModal });
    }

    async termsOfServices(): Promise<void> {
        try {
            const key: RemoteConfigKeyString = ('GENERAL_CONDITIONS_URL_' +
                this.language) as RemoteConfigKeyString;
            const termsUrl = await this.remoteConfig.getString(key);

            if (termsUrl) {
                await Browser.open({ url: termsUrl });
            }
        } catch (error) {
            await this.toast.present({
                message: this.translate.instant('common.errors.default'),
                error,
            });
        }
    }

    async privacyPolicy(): Promise<void> {
        try {
            const key: RemoteConfigKeyString = ('PRIVACY_POLICY_URL_' +
                this.language) as RemoteConfigKeyString;
            const privacyUrl = await this.remoteConfig.getString(key);

            if (privacyUrl) {
                await Browser.open({ url: privacyUrl });
            }
        } catch (error) {
            await this.toast.present({
                message: this.translate.instant('common.errors.default'),
                error,
            });
        }
    }

    async submit(): Promise<void> {
        if (this.isLoading) return;

        if (!(await this.networkService.checkNetWorkStatus())) {
            return;
        }

        this.isLoading = true;

        await this.loaderService.present();

        try {
            await this.authService.login(this.form.value);

            // await Utils.wait(500);
            this.modalService.dismissIonModal();
        } catch (error) {
            let message = 'common.errors.login';
            if (error.code === 'auth/user-not-found' || error.code === 'auth/wrong-password') {
                message = 'common.errors.bad_credentials';
            }

            this.toast.present({
                message: this.translate.instant(message),
                error,
            });
        } finally {
            await this.loaderService.dismiss();
            this.isLoading = false;
        }
    }
}
