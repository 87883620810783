import { AuthState } from 'src/stores/auth/index.state';
import { locationReducer } from 'src/stores/location/index.reducers';
import { LocationState } from 'src/stores/location/index.state';
import { platformReducer } from 'src/stores/platform/index.reducers';
import { PlatformState } from 'src/stores/platform/index.state';
import { GameSurveyState } from 'src/stores/game-survey/game-survey.state';
import { RestaurantsEffects } from 'src/stores/restaurants/index.effects';
import { restaurantsReducer } from 'src/stores/restaurants/index.reducers';
import { RestaurantsState } from 'src/stores/restaurants/index.state';
import { AuthReducer } from './auth/index.reducer';
import { gameSurveyReducer } from 'src/stores/game-survey/game-survey.reducers';
import { registrationReducer } from 'src/stores/registration/registration.reducers';
import { RegistrationState } from 'src/stores/registration/registration.state';
import { settingsReducer } from 'src/stores/settings/settings.reducers';
import { SettingsState } from 'src/stores/settings/settings.state';

export const rootReducer = {
    platform: platformReducer,
    auth: AuthReducer,
    restaurants: restaurantsReducer,
    location: locationReducer,
    gameSurvey: gameSurveyReducer,
    registration: registrationReducer,
    settings: settingsReducer,
};

export const rootEffects = [RestaurantsEffects];

export interface AppState {
    platform: PlatformState;
    auth: AuthState;
    restaurants: RestaurantsState;
    location: LocationState;
    gameSurvey: GameSurveyState;
    registration: RegistrationState;
    settings: SettingsState;
}
