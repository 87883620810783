import { createReducer, on, State } from '@ngrx/store';
import { RestaurantsActions } from 'src/stores/restaurants/index.actions';
import { RestaurantsState } from 'src/stores/restaurants/index.state';

export const initialState: RestaurantsState = {
    searchQuery: '',
    mapCenter: null,
    partners: null,
    nonPartners: null,
};

export const restaurantsReducer = createReducer(
    initialState,
    on(RestaurantsActions.search, (state, { searchQuery }) => ({ ...state, searchQuery })),
    on(RestaurantsActions.centerMap, (state, { mapCenter }) => ({ ...state, mapCenter })),
    on(RestaurantsActions.loadPartnersSuccess, (state, { partners }) => ({ ...state, partners })),
    on(RestaurantsActions.setNonPartners, (state, { items, page, nextPageToken }) => ({
        ...state,
        nonPartners: {
            items,
            page,
            nextPageToken,
        },
    })),
);
