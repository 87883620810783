import { createAction, props } from '@ngrx/store';

const domain = '[SETTINGS]';

const setSkipGameInstructions = createAction(
    `${domain} Set skipGameInstructions`,
    props<{ skipGameInstructions: boolean }>(),
);

export const SettingsActions = {
    setSkipGameInstructions,
};
