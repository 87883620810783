import { Injectable } from '@angular/core';
import { Plugins } from '@capacitor/core';
import { Key } from './index.types';

const { Storage } = Plugins;

@Injectable()
export class LocalStorageService {
    constructor() { }

    async set(key: Key, value: string): Promise<void> {
        await Storage.set({ key, value });
    }

    async get(key: Key): Promise<string> {
        const { value } = await Storage.get({ key });

        return value;
    }

    // tslint:disable-next-line: no-any
    async setObject(key: Key, value: { [key: string]: any }): Promise<void> {
        await Storage.set({
            key,
            value: JSON.stringify(value),
        });
    }

    // tslint:disable-next-line: no-any
    async getObject(key: Key): Promise<{ [key: string]: any }> {
        const { value } = await Storage.get({ key });

        return JSON.parse(value);
    }

    async remove(key: Key): Promise<void> {
        await Storage.remove({ key });
    }

    async keys(): Promise<string[]> {
        const { keys } = await Storage.keys();

        return keys;
    }

    async clear(): Promise<void> {
        await Storage.clear();
    }
}
