import {
    Component,
    EventEmitter,
    forwardRef,
    Host,
    HostBinding,
    Input,
    OnDestroy,
    OnInit,
    Optional,
    Output,
    SkipSelf,
} from '@angular/core';
import {
    ControlContainer,
    ControlValueAccessor,
    FormControl,
    NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HapticService } from 'src/services/haptic.service';

export interface ChoiceOption<T = unknown> {
    value: unknown;
    label: string;
}

@Component({
    selector: 'app-forms-checkbox',
    templateUrl: './checkbox.component.html',
    styleUrls: ['./checkbox.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CheckboxComponent),
            multi: true,
        },
    ],
})
export class CheckboxComponent implements OnInit, OnDestroy, ControlValueAccessor {
    @Input() formControlName: string;
    @Input() label: string;
    @Input() small = false;
    @Input() disabled = false;

    @Output() touched = new EventEmitter<void>();

    onDestroy$ = new Subject<void>();

    control: FormControl;
    checked: boolean;

    propagateChange: (_: boolean) => void;
    propagateTouch: () => void;

    constructor(
        @Optional() @Host() @SkipSelf() private controlContainer: ControlContainer,
        private haptic: HapticService,
    ) {}

    ngOnInit(): void {
        if (this.controlContainer) {
            if (!this.formControlName) {
                throw new Error('formControlName must be defined!');
            }
            this.control = this.controlContainer.control.get(this.formControlName) as FormControl;
        }
        this.touched.pipe(takeUntil(this.onDestroy$)).subscribe(() => this.propagateTouch());
    }

    registerOnChange(fn: (_: unknown) => void): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.propagateTouch = fn;
    }

    writeValue(value: boolean): void {
        this.checked = value;
        if (this.checked) {
            this.control?.markAsTouched();
        }
    }

    setDisabledState(isDisabled: boolean): void {
        isDisabled ? this.control?.disable() : this.control?.enable();
    }

    toggle(): void {
        if (this.disabled) {
            return;
        }
        this.checked = !this.checked;
        this.propagateChange(this.checked);
        this.propagateTouch();

        this.haptic.lightImpact();
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }
}
