import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { NavController } from '@ionic/angular';

@Component({
    selector: 'app-nav-header',
    templateUrl: './nav-header.component.html',
    styleUrls: ['./nav-header.component.scss'],
})
export class NavHeaderComponent {
    @Input() icon?: string;
    @Input() color: 'yellow' | 'darkGray' | 'yellowLight' = 'yellow';
    @Input() showBack = false;
    @Input() showBackPlaceholder = true;
    @Input() showClose = false;
    @Input() backUrl: string | string[];
    @Input() safeArea = true;

    @HostBinding('class.--fixed') @Input() fixed = true;

    @Output() onBack = new EventEmitter<void>();
    @Output() onClose = new EventEmitter<void>();

    constructor(private navCtrl: NavController) {}

    back(): void {
        if (this.backUrl) {
            this.navCtrl.navigateBack(this.backUrl);
        }
        this.onBack.emit();
    }

    close(): void {
        this.onClose.emit();
    }
}
