import { Injectable } from '@angular/core';
import { ForgotPasswordDto, LoginDto, RegisterDto } from './auth.dtos';
import { AngularFireAuth } from '@angular/fire/auth';
import firebase from 'firebase/app';
import 'firebase/auth';
import '@codetrix-studio/capacitor-google-auth';
import { Capacitor, Plugins } from '@capacitor/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AuthLanguage, AuthLogin, AuthSetProfile } from 'src/stores/auth/index.actions';
import { UsersService } from 'src/services/lopp-api/user/user.service';
import { PushNotificationService } from 'src/services/push-notification/index.service';
import { TranslateService } from '@ngx-translate/core';
import { ModalService } from 'src/services/modal.service';
import { SetPhoneNumberModalComponent } from 'src/app/shared/components/set-phone-number-modal/set-phone-number-modal.component';
import { NavController } from '@ionic/angular';
import { DeviceService } from 'src/services/device.service';
const { FirebaseCrashlytics, Device } = Plugins;

@Injectable()
export class AuthService {
    setPhoneNumberOpened = false;

    constructor(
        private ngFireAuth: AngularFireAuth,
        private store: Store,
        private usersService: UsersService,
        private pushNotification: PushNotificationService,
        private translate: TranslateService,
        private modalService: ModalService,
        private navCtrl: NavController,
        private deviceService: DeviceService,
    ) {}

    /**
     * firebasse auth registration (email)
     */
    async register(dto: RegisterDto): Promise<string> {
        const { email, password } = dto;

        // create firebase user
        const { user } = await this.ngFireAuth.createUserWithEmailAndPassword(email, password);

        const token = await user.getIdToken();

        return token;
    }

    /**
     * Get providers for a given email
     */
    providers(email: string): Promise<string[]> {
        return this.ngFireAuth.fetchSignInMethodsForEmail(email);
    }

    /**
     * User email login
     */
    login(dto: LoginDto): Promise<firebase.auth.UserCredential> {
        const { email, password } = dto;

        return this.ngFireAuth.signInWithEmailAndPassword(email, password);
    }

    async impersonate(token: string): Promise<void> {
        //
        await this.ngFireAuth.signInWithCustomToken(token);
        this.navCtrl.navigateForward(['profile']);
    }

    /**
     * Ask for password reset
     */
    forgotPassword(dto: ForgotPasswordDto): Promise<void> {
        const { email } = dto;

        return this.ngFireAuth.sendPasswordResetEmail(email);
    }

    /**
     * User logout
     */
    logout(): Promise<void> {
        return this.ngFireAuth.signOut();
    }

    instance(): AngularFireAuth {
        return this.ngFireAuth;
    }

    getIdToken(): Observable<string> {
        return this.ngFireAuth.idToken;
    }

    async handleFirebaseUser(user: firebase.User): Promise<void> {
        const hasAccount = await this.usersService.hasAccount(await user.getIdToken());
        if (!hasAccount) {
            const language = await this.deviceService.getLanguage();
            this.translate.use(language.toLowerCase());

            return;
        }

        this.pushNotification.enable();

        // set profile to store
        const {
            id: userId,
            lopps,
            totalEarnings,
            totalPendingEarnings,
            firstName,
            lastName,
            avatar,
            language,
            isAmbassador,
            isVerified,
            phoneNumber,
        } = await this.usersService.profile();

        if (Capacitor.getPlatform() !== 'web') {
            FirebaseCrashlytics.setUserId({ userId });
        }

        this.store.dispatch(
            new AuthSetProfile({
                lopps,
                totalEarnings,
                totalPendingEarnings,
                firstName,
                lastName,
                avatar,
                isAmbassador,
            }),
        );

        if (!isVerified) {
            this.setPhoneNumber(false, phoneNumber);
        }

        // // set user language
        this.translate.use(language.toLowerCase());
        this.store.dispatch(new AuthLanguage({ language }));

        // enable login
        this.store.dispatch(new AuthLogin());
    }

    async setPhoneNumber(withClose: boolean, phoneNumber: string = null): Promise<string> {
        if (this.setPhoneNumberOpened) {
            return;
        }

        const instance = await this.modalService.openIonModal({
            component: SetPhoneNumberModalComponent,
            componentProps: { withClose, phoneNumber },
        });

        this.setPhoneNumberOpened = true;

        const { data } = await instance.onWillDismiss<string>();

        this.setPhoneNumberOpened = false;

        return data;
    }
}
