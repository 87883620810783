import { Type } from 'class-transformer';
import { Utils } from 'src/app/shared/utils';
import { Location } from 'src/services/geolocation';

/**
 * REQUESTS
 */

export class GetPartnersDto {
    lat: number;
    lng: number;
}

export class SearchDto {
    lat: number;
    lng: number;
    radius?: number;
    keyword?: string;
    nextPageToken?: string;
}

/**
 * RESPONSES
 */

type PriceLevelLabel =
    | 'restaurants.detail.types.free'
    | 'restaurants.detail.types.inexpensive'
    | 'restaurants.detail.types.moderate'
    | 'restaurants.detail.types.expensive'
    | 'restaurants.detail.types.very_expensive';

export class SearchResultsResponseDto {
    @Type(() => RestaurantResponseDto)
    items: RestaurantResponseDto[];

    nextPageToken?: string;
}

export class RestaurantResponseDto {
    id?: string;
    name: string;
    vicinity: string;
    coverPicture?: string;
    isPartner: boolean;
    googlePlaceId: string;
    location: Location;
    priceLevel: number;
    distance: string;

    getDistance(location: Location): string {
        if (!location) {
            return null;
        }

        const distance = Utils.getDistance(location, this.location);

        if (distance > 1000) {
            return distance / 1000 + ' km';
        } else {
            return distance + ' m';
        }
    }

    get formattedPriceLevel(): PriceLevelLabel {
        switch (this.priceLevel) {
            case 0:
                return 'restaurants.detail.types.free';
                break;
            case 1:
                return 'restaurants.detail.types.inexpensive';
                break;
            case 2:
                return 'restaurants.detail.types.moderate';
                break;
            case 3:
                return 'restaurants.detail.types.expensive';
                break;
            case 4:
                return 'restaurants.detail.types.very_expensive';
                break;
            default:
                return null;
                break;
        }
    }
}

export class RestaurantDetailResponseDto extends RestaurantResponseDto {
    description?: string;
    additionalPictures: string[];
    formattedAddress: string;
}
