import { createReducer, on } from '@ngrx/store';
import { SettingsActions } from 'src/stores/settings/settings.actions';
import { SettingsState } from 'src/stores/settings/settings.state';

export const initialState: SettingsState = {
    skipGameInstructions: false,
};

export const settingsReducer = createReducer(
    initialState,
    on(SettingsActions.setSkipGameInstructions, (state, { skipGameInstructions }) => ({
        ...state,
        skipGameInstructions,
    })),
);
